import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Sub_taskContext } from '../Context/Sub_task';
import { RiEdit2Fill } from "react-icons/ri";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { InputLabel, MenuItem, Select, OutlinedInput, Button, Paper } from '@mui/material';
import './Sub_Task.css';
import { MemberContext } from '../Context/AddMember';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const Sub_Task_Project_Master = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { SubTaskData, Addmember, SubTaskDataFun, SubTastDelete, UpdateSubTask } = useContext(Sub_taskContext);
    const { MemberDataActiveFun, activeuser } = useContext(MemberContext);


    const [task, setTask] = useState('');
    const [editType, setEditType] = useState('');
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [personName, setPersonName] = useState([]); // State for selected names in multi-select
    
    useEffect(() => {
        SubTaskDataFun();
        MemberDataActiveFun();
    }, []);
    
    const handlePage = (id) => {
        navigate(`/subtask/editsubtask/${id}`);
    };

    const Rows = SubTaskData?.data?.data?.map((el, index) => ({
        id: index + 1,
        MainId: el._id,
        SubTask: el?.SubTaskName,
        Member: el?.TeamId?.map((el) => {
            return (
                el.Name
            )
        })

    })) || [];

    const Columns = [
        { field: 'id', headerName: 'Id', flex: 1 },
        { field: 'SubTask', headerName: 'Sub Task Name', flex: 1 },
        { field: 'Member', headerName: 'Member', flex: 1 },
        {
            field: 'showTask', headerName: 'Edit Task', flex: 1,
            renderCell: (params) => (
                <>
                    <Button style={{fontSize:"25px"}}
                        color="primary"
                        onClick={() => handlePage(params.row.MainId)}
                    >
                        <RiEdit2Fill />
                    </Button>
                </>
            ),
        },
    ];


    const names = Array.isArray(activeuser?.data?.data) ? activeuser.data.data.map((el) => ({
        value: el._id,
        label: el.Name
    })) : [];

    // Handle task submission (add/update)
    const handleTaskSubmit = () => {
        if (task) {
            if (editType === 'task') {
                UpdateSubTask(editingTaskId, task);
            } else {
                Addmember(task, personName);
            }
            setTask('');
            setEditType('');
            setEditingTaskId(null);
        } else {
            toast.error('Please enter a task name!', {
                position: 'top-right',
            });
        }
    };
    const handleChange = (event) => {
        const { target: { value } } = event;
        const selectedNames = typeof value === 'string' ? value.split(',') : value;
        setPersonName(selectedNames);
    };
    const handleKeyPressTask = (e) => {
        if (e.key === 'Enter') {
            handleTaskSubmit();
        }
    };

    return (
        <>
            <ToastContainer />
            <Col xs={12}>
                <div className="add-text mt-2 col-12 containers">
                    <h3>Sub Task Master</h3>
                </div>
            </Col>
            <Container fluid="md">
                <Row className="g-sm-5 bg-white mt-5">
                    <Col lg={6} md={12} sm={12}>
                        <div className="form-container p-3  w-100">
                            <h3 className="heading">Sub Task</h3>
                            <input
                                className="input-field form-control "
                                value={task}
                                onChange={(e) => setTask(e.target.value)}
                                placeholder="Enter Sub Task Name"
                                onKeyDown={handleKeyPressTask}
                            />
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <InputLabel id="demo-multiple-name-label">Select Member</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            className='w-100 mt-3'
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
                        >
                            {names.length > 0 ? names.map((name) => (
                                <MenuItem
                                    key={name.value} // Use unique key
                                    value={name.value}
                                    style={{ fontWeight: personName.indexOf(name.value) !== -1 ? 'bold' : 'normal' }}
                                >
                                    {name.label}
                                </MenuItem>
                            )) : <MenuItem disabled>No members available</MenuItem>}
                        </Select>
                    </Col>
                    <Col lg={12}>
                        <button
                            className="submit-button btn btn-primary "
                            onClick={handleTaskSubmit}
                        >
                            {editType === 'task' ? 'Update' : 'Submit'}
                        </button>
                    </Col>
                </Row>
                <Row className='spacer'>
                    <Paper
                        sx={{
                            height: 400,
                            width: '100%',
                            overflow: 'hidden',
                            borderRadius: '20px 20px 0 0',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        }}
                    >
                        <DataGrid
                            rows={Rows} // Safely access rows
                            columns={Columns} // Safely access columns
                            pagination
                            pageSize={5}
                            rowsPerPageOptions={[5, 10]}
                            sx={{
                                border: 0,
                                borderRadius: '0px',
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'black', // Background color for the entire header row
                                    color: 'white', // Text color in the header
                                    borderRadius: '20px 20px 0 0',
                                    boxShadow: 'none',
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: '#91C7EE', // Ensure column header background color matches
                                    color: '#074C7D',
                                    borderBottom: ` 1px solid ${theme.palette.divider}`,
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 'bold',
                                },
                                '& .MuiDataGrid-cell': {
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    visibility: 'hidden',
                                },
                            }}
                            columnVisibilityModel={{
                                ...(isMobile ? { position: false } : {}),
                            }}
                        />
                    </Paper>
                </Row>
            </Container>
        </>
    );
};

export default Sub_Task_Project_Master;
