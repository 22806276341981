import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const DetailsPopup = () => {
  const { id } = useParams(); // Get the task ID from the URL
  const [taskDetails, setTaskDetails] = useState(null);

  useEffect(() => {
    // Fetch task details using the ID
    const fetchTaskDetails = async () => {
      const response = await fetch(`/api/tasks/${id}`); // Replace with your API endpoint
      const data = await response.json();
      setTaskDetails(data);
    };

    fetchTaskDetails();
  }, [id]);

  if (!taskDetails) return <div>Loading...</div>;

  return (
    <div>
      <h2>Task Details</h2>
      {/* Display task details here */}
      <p>Token Name: {taskDetails.tokenName}</p>
      <p>Company Name: {taskDetails.companyName}</p>
      <p>Project: {taskDetails.project}</p>
      <p>Support Team: {taskDetails.supportTeam}</p>
      <p>Date: {taskDetails.date}</p>
      <p>Status: {taskDetails.status}</p>
      {/* Add any other details as needed */}
    </div>
  );
};

export default DetailsPopup;
