import { createContext, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const MemberContext = createContext();

export const MemberContextProvider = ({ children }) => {

    const BaseUrl = "https://customerandtask-app.onrender.com"

    const [MemberData, setMemberData] = useState();
    const Addmember = (Data) => {
        axios
            .post(`${BaseUrl}/DynamicInHouseAccounts/iuyusadjhvfbcsamcbhjhdfh2345`, Data)
            .then(function (response) {
                setMemberData(response)
                if (response?.data.status === "Customer add") {
                    toast.success('Member added successfully!');
                } else {
                    toast.error('Failed to add member. Please try again.');
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const UpadateMember = (editingMemberId, Data) => {
        // console.log(editingMemberId)
        axios
            .post(`${BaseUrl}/DynamicInHouseAccounts/sdgdfgfd54575674sddfsd/${editingMemberId}`, Data)
            .then(function (response) {
                setMemberData(response)
                if (response?.data.status === "Customer update") {
                    toast.success('Member Update successfully!');
                } else {
                    toast.error('Failed to add member. Please try again.');
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const onoffMember = (id) => {
        axios
            .get(`${BaseUrl}/DynamicInHouseAccounts/sdgdfgfd54575674ssssss/${id}`)
            .then(function (response) {
                setMemberData(response)
                if (response?.data.status === "Customer update") {
                    toast.success('Member Update successfully!');
                } else {
                    toast.error('Failed to add member. Please try again.');
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const MemberDataFun = () => {
        axios
            .get(`${BaseUrl}/DynamicInHouseAccounts/arfgdfhfghfghhfhhfhgfhfh`)
            .then(function (response) {
                setMemberData(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    const [activeuser,setactiveuser] = useState()

    const MemberDataActiveFun = () => {
        axios
            .get(`${BaseUrl}/DynamicInHouseAccounts/arfgdfhfghfghhfhhfhgfhgg`)
            .then(function (response) {
                setactiveuser(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    return (
        <MemberContext.Provider
            value={{
                Addmember, MemberData, MemberDataFun, UpadateMember, onoffMember,MemberDataActiveFun,activeuser
            }}
        >
            {children}
        </MemberContext.Provider>
    );
};

// Add prop-types validation
MemberContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
