import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaUpload } from 'react-icons/fa';
import Table from '../Mui/Table';
import { MemberContext } from '../Context/AddMember';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Add_Member = () => {
    const { Addmember, MemberData, MemberDataFun, UpadateMember, onoffMember } = useContext(MemberContext);

    const [filePreview, setFilePreview] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editingMemberId, setEditingMemberId] = useState(null);

    useEffect(() => {
        MemberDataFun();
    }, []);

    const formik = useFormik({
        initialValues: {
            Name: '',
            Email: '',
            Role: '',
            MobileNumber: '',
            Password: '',
            Address: '',
            ProfilePhoto: null,
            JoiningDate: '',
        },
        validationSchema: Yup.object({
            Name: Yup.string().min(3, 'At least 3 characters').required('Required'),
            Email: Yup.string().email('Invalid email').required('Required'),
            Role: Yup.string().required('Required'),
            MobileNumber: Yup.string().matches(/^[0-9]{10}$/, '10-digit number required').required('Required'),
            Password: Yup.string().min(6, 'At least 6 characters').required('Required'),
            Address: Yup.string().min(10, 'At least 10 characters').required('Required'),
            ProfilePhoto: Yup.mixed().required('Required'),
            JoiningDate: Yup.date().required('Required').nullable(),
        }),
        onSubmit: (values, { resetForm }) => {
            if (editing) {
                UpadateMember(editingMemberId.MainId, values);
            } else {
                Addmember(values);
            }
            resetForm();
            setEditing(false);
            setEditingMemberId(null);
        },
    });

    const handleFileChange = async (event) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue('ProfilePhoto', file);

        if (file && file.type.startsWith('image/') && file.size <= 1048576) {
            const reader = new FileReader();
            reader.onload = () => setFilePreview(reader.result);
            reader.readAsDataURL(file);

            const data = new FormData();
            data.append("file", file);
            data.append("upload_preset", "z1b1yym5");
            data.append("cloud_name", "dtyld5r5v");

            const cloudinaryResponse = await fetch(
                "https://api.cloudinary.com/v1_1/ditwdks61/upload",
                {
                    method: "post",
                    body: data,
                }
            );
            const cloudinaryData = await cloudinaryResponse.json();
            formik.setFieldValue('ProfilePhoto', cloudinaryData.secure_url);
        } else {
            setFilePreview(null);
        }
    };

    const handleEdit = (memberData) => {
        setEditing(true);
        setEditingMemberId(memberData);
        formik.setValues({
            Name: memberData.MemberName,
            Email: memberData.Email,
            Password: memberData.Password,
            Role: memberData.Role,
            MobileNumber: memberData.mobileNumber,
            Address: memberData.Address,
            ProfilePhoto: memberData.ProfilePhoto,
            JoiningDate: memberData.JoiningDate,
        });
        setFilePreview(memberData.ProfilePhoto); // Set the preview for the profile photo
    };

    const handleStatusToggle = (id) => {
        // console.log("Toggling status for ID:", id);
        onoffMember(id); // Call the function to toggle the status
    };
    
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'MemberName', headerName: 'Member Name', flex: 1 },
        { field: 'mobileNumber', headerName: 'Mobile Number', flex: 1 },
        { field: 'Role', headerName: 'Role', flex: 1 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <button
                    onClick={() => handleEdit(params.row)}
                    className="btn btn-primary"
                >
                    Edit
                </button>
            ),
        },

        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => (
                <button
                    onClick={() => handleStatusToggle(params.row.MainId)}
                    className={`btn ${params.row.Profile ? 'btn-success' : 'btn-danger'}`}
                    style={{ backgroundColor: params.row.Profile === "On" ? 'blue' : 'red', color: 'white' }}
                >
                    {params.row.Profile}
                </button>
            ),
        }
    ];

    const rows = MemberData?.data?.data?.map((el, index) => ({
        id: el.id || index + 1,
        MainId: el._id,
        mobileNumber: el.MobileNumber,
        MemberName: el.Name,
        Email: el.Email,
        Password: el.Password,
        Role: el.Role,
        Address: el.Address,
        ProfilePhoto: el.ProfilePhoto,
        JoiningDate: el.JoiningDate,
        Profile: el.Profile,
    })) || [];

    return (
        <div className="container">
            <form onSubmit={formik.handleSubmit} className='Add_Member box-add_member'>
                <div className="row g-3">
                    <div className="col-lg-6">
                        <input
                            id="name"
                            name="Name"
                            placeholder='Full Name'
                            type="text"
                            className="form-control"
                            {...formik.getFieldProps('Name')}
                        />
                        {formik.touched.Name && formik.errors.Name && (
                            <div style={{ color: 'red' }}>{formik.errors.Name}</div>
                        )}
                    </div>

                    <div className="col-lg-6">
                        <select id="Role" name="Role" className='form-select' {...formik.getFieldProps('Role')}>
                            <option value="" label="Select Role" />
                            <option value="owner" label="Owner" />
                            <option value="customer support" label="Customer Support" />
                            <option value="developer" label="Developer" />
                            <option value="inhouse" label="Inhouse" />
                        </select>
                        {formik.touched.Role && formik.errors.Role && (
                            <div style={{ color: 'red' }}>{formik.errors.Role}</div>
                        )}
                    </div>
                </div>

                <div className="row g-3">
                    <div className="col-lg-3 d-flex justify-content-center align-items-center">
                        <div className="box border p-3 text-center" onClick={() => document.getElementById('fileInput').click()} style={{ cursor: 'pointer', width: '100%', height: '200px' }}>
                            {filePreview ? (
                                <img src={filePreview} alt="Preview" style={{ maxHeight: '100%', maxWidth: '100%' }} />
                            ) : <FaUpload size={30} />}
                        </div>
                        <input
                            id="fileInput"
                            name="ProfilePhoto"
                            type="file"
                            className='d-none'
                            onChange={handleFileChange}
                        />
                        {formik.touched.ProfilePhoto && formik.errors.ProfilePhoto && (
                            <div style={{ color: 'red' }}>{formik.errors.ProfilePhoto}</div>
                        )}
                    </div>

                    <div className="col-lg-9">
                        <div className="row g-3">
                            <div className="col-lg-6">
                                <input
                                    id="mobileNumber"
                                    name="MobileNumber"
                                    placeholder='Contact Number'
                                    type="text"
                                    className="form-control"
                                    {...formik.getFieldProps('MobileNumber')}
                                />
                                {formik.touched.MobileNumber && formik.errors.MobileNumber && (
                                    <div style={{ color: 'red' }}>{formik.errors.MobileNumber}</div>
                                )}
                            </div>

                            <div className="col-lg-6">
                                <input
                                    id="email"
                                    name="Email"
                                    placeholder='Email Address'
                                    type="email"
                                    className="form-control"
                                    {...formik.getFieldProps('Email')}
                                />
                                {formik.touched.Email && formik.errors.Email && (
                                    <div style={{ color: 'red' }}>{formik.errors.Email}</div>
                                )}
                            </div>
                        </div>

                        <div className="row g-3 mt-3">
                            <div className="col-lg-6">
                                <input
                                    id="password"
                                    name="Password"
                                    placeholder='Password'
                                    type={showPassword ? 'text' : 'password'}
                                    className="form-control"
                                    {...formik.getFieldProps('Password')}
                                />
                                {formik.touched.Password && formik.errors.Password && (
                                    <div style={{ color: 'red' }}>{formik.errors.Password}</div>
                                )}
                            </div>

                            <div className="col-lg-6">
                                <input
                                    id="address"
                                    name="Address"
                                    placeholder='Address'
                                    type="text"
                                    className="form-control"
                                    {...formik.getFieldProps('Address')}
                                />
                                {formik.touched.Address && formik.errors.Address && (
                                    <div style={{ color: 'red' }}>{formik.errors.Address}</div>
                                )}
                            </div>
                        </div>

                        <div className="row g-3 mt-3">
                            <div className="col-lg-6">
                                <input
                                    id="joiningDate"
                                    name="JoiningDate"
                                    placeholder='Joining Date'
                                    type="date"
                                    className="form-control"
                                    {...formik.getFieldProps('JoiningDate')}
                                />
                                {formik.touched.JoiningDate && formik.errors.JoiningDate && (
                                    <div style={{ color: 'red' }}>{formik.errors.JoiningDate}</div>
                                )}
                            </div>

                            <div className="col-lg-6 d-flex justify-content-end align-items-center">
                                <button type="submit" className="btn btn-primary" style={{ padding: '10px 20px', fontSize: '16px' }}>
                                    {editing ? 'Update Member' : 'Add Member'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div className='mt-5'>
                <Table rows={rows} columns={columns} />
            </div>

            <ToastContainer />
        </div>
    );
};

export default Add_Member;
