import React, { useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Header.css'; // Ensure you have corresponding styles
import Search from './Search';
import { IoSearch } from "react-icons/io5";
import Cookies from 'js-cookie'; // Import js-cookie

const Header = ({ onToggleSidebar, isSidebarOpen }) => {
    const loginData = Cookies.get('loginData');
    const parsedData = loginData ? JSON.parse(loginData) : null;  // Use null if loginData is undefined
    // console.log(parsedData);

    // Automatically close sidebar on small and medium screen sizes (md and smaller)
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // md breakpoint

        const handleResize = () => {
            if (mediaQuery.matches && isSidebarOpen) {
                onToggleSidebar(); // Close sidebar if it's open on small screens
            }
        };

        mediaQuery.addListener(handleResize);
        return () => mediaQuery.removeListener(handleResize); // Clean up listener on component unmount
    }, [isSidebarOpen, onToggleSidebar]);

    return (
        <header className='bg-header d-flex align-items-center'>
            <div style={{ paddingLeft: "25%" }} className="header d-flex">
                <div className="menu-button">
                    <button
                        className="menu-toggle"
                        onClick={onToggleSidebar}
                        aria-label={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
                    >
                        {isSidebarOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </div>
                <div className='d-flex justify-content-xl-around justify-content-lg-center w-100'>
                    <div className="header-left d-none d-lg-block">
                        <h1>Task Management</h1>
                    </div>
                    <div className="header-right d-flex align-items-center">
                        <div className="input-search me-3 d-none d-md-block d-lg-block">
                            <div className="icon d-flex align-items-center">
                                <i className='fs-3' style={{ marginRight: '-40px' }}><IoSearch /></i>
                                <input type="text" placeholder="Search..." aria-label="Search" />
                            </div>
                        </div>
                        <div className='d-md-none d-block'>
                            <Search />
                        </div>
                    </div>
                </div>
            </div>

            <div className="login image d-flex align-items-center">
                <img src={parsedData?.data?.Photo} style={{ height: '50px', width: '50px', borderRadius: '50%' }} alt="" />
                <span className='ms-3 d-none d-lg-block d-md-block'>{parsedData?.data?.Name}</span>
            </div>
        </header>
    );
};

export default Header;
