import React from 'react'
import Assign_Task_Form from '../Components/Assign_Task_Form'
import { Col, Row } from 'react-bootstrap'

const Assign_task = () => {
  return (
    <div>

      <div className="containers">
        <div className="add-text mt-3">
          <h3>Assign Task</h3>
        </div>
        <Row className='mt-3'>
          <Assign_Task_Form />
        </Row>
      </div>
    </div>
  )
}

export default Assign_task
