import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';
import Table from '../Mui/Table';
import './Show_All_Member.css';
import { MemberContext } from '../Context/AddMember';

// Custom style for the MUI modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const HomeInhouse = () => {
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('All'); // Add state for filter
    const { MemberData, MemberDataFun } = useContext(MemberContext);

    useEffect(() => {
        MemberDataFun();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'Name', headerName: 'Name', flex: 1 },
        { field: 'MobileNumber', headerName: 'Mobile Number', flex: 1 },
        { field: 'Profile', headerName: 'Profile', flex: 1 },
        {
            field: 'profileLink',
            headerName: 'Profile',
            flex: 1,
            renderCell: (params) => (
                <a href={params.value} onClick={(e) => handleProfileClick(e, params.row)}>
                    View Profile
                </a>
            ),
        },
    ];

    // Map rows for the table
    const rows = MemberData?.data?.data?.map((el, index) => ({
        id: el.id || index + 1,
        Name: el.Name,
        MobileNumber: el.MobileNumber,
        Profile: el.Profile,
        MainId: el._id,
        Email: el.Email,
    })) || [];

    // Calculate counts for each filter category
    const totalMembers = rows.length;
    const activeMembersCount = rows.filter((row) => row.Profile === 'On').length;
    const nonActiveMembersCount = rows.filter((row) => row.Profile === 'Off').length;

    // Handle filter logic based on selected button
    const filteredRows = rows.filter((row) => {
        if (filter === 'All') return true;
        if (filter === 'Active') return row.Profile === 'On';
        if (filter === 'NonActive') return row.Profile === 'Off';
        return true;
    });

    // Handle profile click to show modal
    const handleProfileClick = (event, rowData) => {
        event.preventDefault();
        setSelectedProfile(rowData);
        setOpen(true);
    };

    const handleClose = () => setOpen(false); // Close modal function

    return (
        <>
            <Row>
                <div className="add-text mt-2 containers">
                    <h3>Show All Members</h3>
                </div>
            </Row>
            <div className="containers">
                <Row style={{ background: "#91C7EE", padding: "20px", borderRadius: "10px" }} className="mb-3">
                    <Col>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color={filter === 'All' ? 'primary' : 'default'}
                                    onClick={() => setFilter('All')}
                                >
                                    All Members ({totalMembers})
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color={filter === 'Active' ? 'primary' : 'default'}
                                    onClick={() => setFilter('Active')}
                                >
                                    Active Members ({activeMembersCount})
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color={filter === 'NonActive' ? 'primary' : 'default'}
                                    onClick={() => setFilter('NonActive')}
                                >
                                    Non-Active Members ({nonActiveMembersCount})
                                </Button>
                            </Grid>
                        </Grid>
                    </Col>
                </Row>

                <Row className="containers spacer g-xl-3 g-lg-2">
                    <Col lg={12}>
                        <Row className="g-5">
                            <Col lg={12}>
                                <Table columns={columns} rows={filteredRows} /> {/* Use filtered rows */}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Modal for showing profile details */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Profile Details
                        </Typography>
                        {selectedProfile && (
                            <div className="profile-details">
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <p><strong>Name:</strong> {selectedProfile.Name}</p>
                                    <p><strong>Email:</strong> {selectedProfile.Email}</p>
                                    <p><strong>Mobile Number:</strong> {selectedProfile.MobileNumber}</p>
                                </Typography>
                            </div>
                        )}
                        <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
                            Close
                        </Button>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default HomeInhouse;
