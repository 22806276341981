import React, { useState, useEffect } from 'react';
import { IoMdSearch } from 'react-icons/io';
import './Search.css'; 

const Search = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    return (
        <div className="search-container">
            <button onClick={openModal} aria-label="Open Search Modal" className="search-button">
                <IoMdSearch />
            </button>
            {isModalOpen && (
                <div className={`modal-overlay ${isModalOpen ? 'open' : ''}`} onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="modal-close" onClick={closeModal} aria-label="Close Modal">
                            &times;
                        </button>
                        <input type="text" placeholder="Search..." aria-label="Search Input" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Search;
