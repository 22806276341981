import { Box, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
    TextField,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    Typography,
} from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import { Sub_taskContext } from "../Context/Sub_task";
import { TastMasterContext } from "../Context/Task_master";
import { MemberContext } from "../Context/AddMember";
import { useMediaQuery } from "@mui/material";
import {  useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateTask = () => {
    const { SubTaskData, SubTaskDataFun, ProjectData, ProjectDataFun } = useContext(Sub_taskContext);
    const { ShowAllTask, tasldata, ShowTask, UpdateTask } = useContext(TastMasterContext);
    const { MemberDataActiveFun, activeuser } = useContext(MemberContext);
    const { id } = useParams();

    useEffect(() => {
        ShowTask(id);
    }, []);
    // console.log(tasldata?.data?.data);
    const [members, setMembers] = useState([]);
    const [selectedMemberId, setSelectedMemberId] = useState('');

    const [Task, setTask] = useState([]);
    const [selectedTaskId, setselectedTaskId] = useState('');


    const [formValues, setFormValues] = useState({
        Description: tasldata?.data?.data?.Description,
        DurationeType: tasldata?.data?.data?.DurationeType,
        TastName: tasldata?.data?.data?.TastName,
        Duration: tasldata?.data?.data?.Duration,
        Project: tasldata?.data?.data?.Project?._id,
        Type: tasldata?.data?.data?.Type,
        Week: tasldata?.data?.data?.Week,
        Day: tasldata?.data?.data?.Day,
        Date: tasldata?.data?.data?.Date,
    });

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (!SubTaskData || !ProjectData) {
            SubTaskDataFun();
            ProjectDataFun();
            ShowAllTask();
            MemberDataActiveFun();
        }
    }, []);

    const PROJECTS =
        ProjectData?.data?.data?.map((el) => ({
            project_id: el._id,
            project_name: el.project_name,
        })) || [];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formValues.Description) {
            errors.Description = "Description is required";
        }
        if (!formValues.DurationeType) {
            errors.DurationeType = "DurationeType is required";
        }
        if (!formValues.TastName) {
            errors.TastName = "Task Name is required";
        }
        if (!formValues.Duration || formValues.Duration <= 0) {
            errors.Duration = "Duration must be a positive number";
        }
        if (!formValues.Project) {
            errors.Project = "Project Name is required";
        }
        // if (!formValues.TeamId.length) {
        //   errors.TeamId = "At least one member must be selected";
        // }
        if (!formValues.Type) {
            errors.Type = "Task Type is required";
        }
        return errors;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const initialMembers1 = tasldata?.data?.data?.SubTask || [];
        // console.log('Initial Members:', initialMembers1);
        setTask(initialMembers1.map(el => ({ id: el._id, name: el.SubTaskName })));
    }, []);

    const handleSelectChange1 = (e) => {
        setselectedTaskId(e.target.value);
    };

    const handleAddMember1 = () => {
        const selectedMember = SubTaskData?.data?.data.find(member => member._id === selectedTaskId);
        if (selectedMember && !Task.some(member => member.id === selectedMember._id)) {
            setTask([...Task, { id: selectedMember._id, name: selectedMember.SubTaskName }]);
            setselectedTaskId('');
        } else {
            toast.error('Member already added or not selected!');
        }
    };

    const handleRemoveMember1 = (id) => {
        setTask(Task.filter(member => member.id !== id));
        toast.success('Member removed successfully!');
    };


    const handleDragStart1 = (e, index) => {
        e.dataTransfer.setData('text/plain', index);
    };

    const handleDrop1 = (e, dropIndex) => {
        e.preventDefault();
        const dragIndex = e.dataTransfer.getData('text/plain');
        const updatedMembers = [...Task];
        if (dragIndex !== dropIndex.toString()) {
            const [movedMember] = updatedMembers.splice(dragIndex, 1);
            updatedMembers.splice(dropIndex, 0, movedMember);
            setTask(updatedMembers);
        }
    };

    const handleDragOver1 = (e) => {
        e.preventDefault();
    };
    useEffect(() => {
        const initialMembers = tasldata?.data?.data?.TeamId || [];
        // console.log('Initial Members:', initialMembers);
        setMembers(initialMembers.map(el => ({ id: el._id, name: el.Name })));
    }, []);

    const handleSelectChange = (e) => {
        setSelectedMemberId(e.target.value);
    };

    const handleAddMember = () => {
        const selectedMember = activeuser?.data?.data.find(member => member._id === selectedMemberId);
        if (selectedMember && !members.some(member => member.id === selectedMember._id)) {
            setMembers([...members, { id: selectedMember._id, name: selectedMember.Name }]);
            setSelectedMemberId('');
        } else {
            toast.error('Member already added or not selected!');
        }
    };

    const handleRemoveMember = (id) => {
        setMembers(members.filter(member => member.id !== id));
        toast.success('Member removed successfully!');
    };


    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('text/plain', index);
    };

    const handleDrop = (e, dropIndex) => {
        e.preventDefault();
        const dragIndex = e.dataTransfer.getData('text/plain');
        const updatedMembers = [...members];
        if (dragIndex !== dropIndex.toString()) {
            const [movedMember] = updatedMembers.splice(dragIndex, 1);
            updatedMembers.splice(dropIndex, 0, movedMember);
            setMembers(updatedMembers);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            const TeamId = members.map(member => member.id);
            const SubTask = Task.map(member => member.id);
            const updatedFormValues = {
                ...formValues,
                TeamId,  // Add the array of member ids
                SubTask      // Add the array of task ids
            };
            // console.log(id, updatedFormValues);
            
            UpdateTask(id, updatedFormValues)

        } else {
            setFormErrors(errors);
        }
    };
    return (
        <div className="containers">
            <Row className="spacer">
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        width: "100%",
                        backgroundColor: "#ffffff",
                        padding: 2,

                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" gutterBottom className="mb-4">
                        Assign Task Form
                    </Typography>

                    <Grid container spacing={3}>
                        {/* Description Field */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="Description"
                                name="Description"
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={formValues.Description}
                                onChange={handleChange}
                                error={!!formErrors.Description}
                                helperText={formErrors.Description}
                                sx={{ marginBottom: 3 }}
                            />
                        </Grid>

                        {/* Task Name Field */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="TastName"
                                name="TastName"
                                label="Task Name"
                                variant="outlined"
                                multiline
                                rows={1}
                                value={formValues.TastName}
                                onChange={handleChange}
                                error={!!formErrors.TastName}
                                helperText={formErrors.TastName}
                                sx={{ marginBottom: 3 }}
                            />
                        </Grid>

                        {/* Duration Field */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="Duration"
                                name="Duration"
                                label="Duration"
                                type="number"
                                variant="outlined"
                                value={formValues.Duration}
                                onChange={handleChange}
                                error={!!formErrors.Duration}
                                helperText={formErrors.Duration}
                                sx={{ marginBottom: 3 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                id="DurationeType"
                                name="DurationeType"
                                label="Duration Type"
                                value={formValues.DurationeType}
                                onChange={handleChange}
                                error={!!formErrors.DurationeType}
                                helperText={formErrors.DurationeType}
                                sx={{ marginBottom: 3 }}
                            >
                                <MenuItem value="Day">Day</MenuItem>
                                <MenuItem value="Hours">Hours</MenuItem>
                            </TextField>
                        </Grid>

                        {/* Project Name Field */}
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth
                                sx={{ marginBottom: 3 }}
                                error={!!formErrors.Project}
                            >
                                <InputLabel id="project-name-label">Project Name</InputLabel>
                                <Select
                                    labelId="project-name-label"
                                    id="Project"
                                    name="Project"
                                    value={formValues.Project}
                                    onChange={handleChange}
                                    label="Project Name"
                                >
                                    {PROJECTS.map((project) => (
                                        <MenuItem
                                            key={project.project_id}
                                            value={project.project_id}
                                        >
                                            {project.project_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{formErrors.Project}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth
                                sx={{ marginBottom: 3 }}
                                error={!!formErrors.Type}
                            >
                                <InputLabel id="task-type-label">Task Type</InputLabel>
                                <Select
                                    labelId="task-type-label"
                                    id="Type"
                                    name="Type"
                                    value={formValues.Type}
                                    onChange={handleChange}
                                    label="Task Type"
                                >
                                    <MenuItem value="One Time">One Time</MenuItem>
                                    <MenuItem value="Daily">Daily</MenuItem>
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    <MenuItem value="Semiannualy">Semiannualy</MenuItem>
                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                </Select>
                                <FormHelperText>{formErrors.Type}</FormHelperText>
                            </FormControl>
                        </Grid>
                        {formValues.Type === "Weekly" ? (
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    sx={{ marginBottom: 3 }}
                                    error={!!formErrors.Type}
                                >
                                    <InputLabel id="task-type-label">Select Week</InputLabel>
                                    <Select
                                        labelId="task-type-label"
                                        id="Week"
                                        name="Week"
                                        value={formValues.Week}
                                        onChange={handleChange}
                                        label="Task Type"
                                    >
                                        {[
                                            "Monday",
                                            "Tuesday",
                                            "Wednesday",
                                            "Thursday",
                                            "Friday",
                                            "Saturday",
                                            "Sunday",
                                        ].map((day) => (
                                            <MenuItem key={day} value={day}>
                                                {day}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{formErrors.Type}</FormHelperText>
                                </FormControl>
                            </Grid>
                        ) : formValues.Type === "One Time" ||
                            formValues.Type === "Daily" ? null : (
                            formValues.Type && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            fullWidth
                                            sx={{ marginBottom: 3 }}
                                            error={!!formErrors.Date}
                                        >
                                            <InputLabel id="date-label">Select Date</InputLabel>
                                            <Select
                                                labelId="date-label"
                                                id="Date"
                                                name="Date"
                                                value={formValues.Date}
                                                onChange={handleChange}
                                                label="Date"
                                            >
                                                {/* Month Selector */}
                                                {[...Array(12).keys()].map((month) => (
                                                    <MenuItem
                                                        key={month}
                                                        value={new Date(0, month).toLocaleString(
                                                            "default",
                                                            { month: "long" }
                                                        )}
                                                    >
                                                        {new Date(0, month).toLocaleString("default", {
                                                            month: "long",
                                                        })}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{formErrors.Date}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            id="day"
                                            name="Day"
                                            label="Select Day"
                                            type="number"
                                            value={formValues.Day}
                                            onChange={handleChange}
                                            error={!!formErrors.Day}
                                            helperText={formErrors.Day}
                                            InputProps={{ inputProps: { min: 1, max: 31 } }}
                                        />
                                    </Grid>
                                </>
                            )
                        )}
                    </Grid>
                    <Grid style={{ paddingTop: "50px" }} container spacing={3} >
                        <Grid item xs={12} sm={6}>
                            <div className="form-container p-3 w-100">
                                <h3 className="heading">Select Member</h3>
                                <Form.Group>
                                    <Form.Select
                                        value={selectedMemberId}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="">Select a member</option>
                                        {activeuser?.data?.data?.map((member) => (
                                            <option key={member._id} value={member._id}>
                                                {member.Name}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    <Row className="justify-content-end">
                                        <Col xs="auto">
                                            <Button onClick={handleAddMember} className="mt-3">
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                            <div className="form-container p-3 w-100">
                                <h3 className="heading">Members List</h3>
                                <div
                                    style={{
                                        border: "1px dashed gray",
                                        padding: "10px",
                                        minHeight: "50px",
                                    }}
                                >
                                    {members.length > 0 ? (
                                        members.map((member, index) => (
                                            <div
                                                key={member.id}
                                                draggable
                                                onDragStart={(e) => handleDragStart(e, index)}
                                                onDrop={(e) => handleDrop(e, index)}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    padding: "8px",
                                                    margin: "4px 0",
                                                    border: "1px solid #ccc",
                                                    cursor: "grab",
                                                    backgroundColor: "#f9f9f9",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                {member.name}
                                                <Button variant="danger" size="sm" onClick={() => handleRemoveMember(member.id)}>Remove</Button>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No members available.</p>
                                    )}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="form-container p-3 w-100">
                                <h3 className="heading">Select Sub Task</h3>
                                <Form.Group>
                                    <Form.Select
                                        value={selectedTaskId}
                                        onChange={handleSelectChange1}
                                    >
                                        <option value="">Select Sub Task</option>
                                        {SubTaskData?.data?.data?.map((member) => (
                                            <option key={member._id} value={member._id}>
                                                {member.SubTaskName}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    <Row className="justify-content-end">
                                        <Col xs="auto">
                                            <Button onClick={handleAddMember1} className="mt-3">
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                            <div className="form-container p-3 w-100">
                                <h3 className="heading">Sub Task List</h3>
                                <div
                                    style={{
                                        border: "1px dashed gray",
                                        padding: "10px",
                                        minHeight: "50px",
                                    }}
                                >
                                    {Task.length > 0 ? (
                                        Task.map((member, index) => (
                                            <div
                                                key={member.id}
                                                draggable
                                                onDragStart={(e) => handleDragStart1(e, index)}
                                                onDrop={(e) => handleDrop1(e, index)}
                                                onDragOver={handleDragOver1}
                                                style={{
                                                    padding: "8px",
                                                    margin: "4px 0",
                                                    border: "1px solid #ccc",
                                                    cursor: "grab",
                                                    backgroundColor: "#f9f9f9",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                {member.name}
                                                <Button variant="danger" size="sm" onClick={() => handleRemoveMember1(member.id)}>Remove</Button>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No members available.</p>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {/* Submit Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{ marginTop: 2 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Row>
            <ToastContainer />
        </div>
    );
};

export default UpdateTask;
