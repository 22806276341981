import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Show_All_Member.css';
import Table from '../Mui/Table';
import { Modal, Box, Typography, Button, Avatar } from '@mui/material';
import { MemberContext } from '../Context/AddMember';

const ShowAllMember = () => {
  // State for selected profile and modal visibility
  const { MemberData, MemberDataFun } = useContext(MemberContext);

  useEffect(() => {
    MemberDataFun();
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  // Define columns for Table
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'mobileNumber', headerName: 'Mobile Number', flex: 1 },
    { field: 'position', headerName: 'Position', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      field: 'profileLink', headerName: 'Profile', flex: 1, renderCell: (params) => (
        <a href={params.value} onClick={(e) => handleProfileClick(e, params.row)}>
          View Profile
        </a>
      )
    }
  ];

  const tasks = [
    { id: 'pending', name: 'Pending Task' },
    { id: 'running', name: 'Running Task' },
    { id: 'completed', name: 'Completed Task' },
  ];

  // Extract rows data from MemberData
  const rows = MemberData?.data?.data
    ?.filter(el => el.Profile && el.Profile.includes("On")) // Filter positions containing "On"
    .map((el, index) => ({
      id: el.id || index + 1,
      MainId: el._id,
      mobileNumber: el.MobileNumber,
      name: el.Name,
      Email: el.Email,
      Password: el.Password,
      position: el.Role,
      Address: el.Address,
      ProfilePhoto: el.ProfilePhoto,
      JoiningDate: el.JoiningDate,
      status: el.Profile,
    })) || [];

  // Event handler for profile link click
  const handleProfileClick = (event, rowData) => {
    event.preventDefault();
    setSelectedProfile(rowData); // Set the selected profile data
    setOpen(true); // Open the modal
  };

  // Function to handle closing the modal
  const handleClose = () => setOpen(false);

  // Event handler for "Show Task" button click

  // Modal style
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Row>
        <div className="add-text mt-2 containers">
          <h3>Show All Members</h3>
        </div>
      </Row>
      <Row className="containers spacer g-xl-3 g-lg-2">
        <Col lg={12}>
          <Row className='g-5'>
            <Col lg={12}>
              <Table columns={columns} rows={rows} />
            </Col>
          </Row>
        </Col>
      </Row>
      

      {/* Modal for showing profile details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Profile Details
          </Typography>
          {selectedProfile && (
            <div className="profile-details">
              <Avatar
                alt={selectedProfile.name}
                src={selectedProfile.ProfilePhoto}
                sx={{ width: 100, height: 100, mb: 2 }}
              />
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <p><strong>ID:</strong> {selectedProfile.id}</p>
                <p><strong>Name:</strong> {selectedProfile.name}</p>
                <p><strong>Email:</strong> {selectedProfile.Email}</p>
                <p><strong>Mobile Number:</strong> {selectedProfile.mobileNumber}</p>
                <p><strong>Position:</strong> {selectedProfile.position}</p>
                <p><strong>Status:</strong> {selectedProfile.status}</p>
              </Typography>
            </div>
          )}
          <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default ShowAllMember;
