import React from 'react'
import Task_Form from '../Components/Task_Form'
import './Task_master.css'

const Task_Master = () => {
  return (
    <>
      
      <div className="conatiners">
        <Task_Form />
      </div>
    </>
  )
}

export default Task_Master