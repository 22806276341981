import { createContext, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import axios from "axios";
import Cookies from 'js-cookie'; // Import js-cookie
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const BaseUrl = "https://customerandtask-app.onrender.com"

    const [LoginData, setLoginData] = useState();

    const Login = (Email, Password) => {
        axios
            .post(`${BaseUrl}/CustomerSupport/sdfghjklgfdsartfyuiopytr`, {
                Password: Password,
                Email: Email
            })
            .then(function (response) {
                if (response.data.status === "Login as Owner" || response.data.status === "Login as InHouse" || response.data.status === "customer support") {
                    setLoginData(response)
                    Cookies.set('loginData', JSON.stringify(response.data), { expires: 7 });
                    window.location.reload()

                } else {
                    toast.error(`${response.data.status}`, {
                        position: "top-right",
                        autoClose: 3000,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    return (
        <AuthContext.Provider
            value={{
                Login,LoginData
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// Add prop-types validation
AuthContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
