import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { TastMasterContext } from '../Context/Task_master';
import Cookies from 'js-cookie'; // Import js-cookie
import { Col, Row } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { MemberContext } from '../Context/AddMember';


const TaskDetailsDetail = () => {
  const theme = useTheme();

  const { MemberDataActiveFun, activeuser } = useContext(MemberContext);


  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };
  const { id } = useParams();

  const loginData = Cookies.get('loginData');
  const parsedData = loginData ? JSON.parse(loginData) : null;

  const { TaskIdfunTione, TaskId, AssingsubTaskChangeMemberr } = useContext(TastMasterContext);


  useEffect(() => {
    TaskIdfunTione(id);
    MemberDataActiveFun()
  }, [])
  // console.log(TaskId);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedMemberId, setSelectedMemberId] = useState('');

  const Columns = [
    { field: 'id', headerName: 'Id', flex: 1 },
    { field: 'SubTaskName', headerName: 'Sub Task Name', flex: 1 },
    { field: 'Member', headerName: 'Assing To', flex: 1 },
    { field: 'AssingBy', headerName: 'Assing By', flex: 1 },

    { field: 'Status', headerName: 'Status', flex: 1 },
    { field: 'AssignDate', headerName: 'Assign Date', flex: 1 },
  ];

  const Rows = TaskId?.data?.data3?.map((el, index) => ({
    id: index + 1,
    MainId: el._id,
    Member: el?.MemberId?.Name,
    AssingBy: el?.AssignedBy?.Name,
    Status: el.Status,
    AssignDate: formatDate(el.AssignDate),
    SubTaskName: el.SubTaskId.SubTaskName
  })) || [];

  const MainColumns = [
    { field: 'id', headerName: 'Id', flex: 1 },
    { field: 'SubTaskName', headerName: 'Sub Task Name', flex: 1 },
    { field: 'Member', headerName: 'Assing To', flex: 1 },
    { field: 'AssingBy', headerName: 'Assing By', flex: 1 },
    { field: 'Status', headerName: 'Status', flex: 1 },
    { field: 'AssignDate', headerName: 'Assign Date', flex: 1 },
    {
      field: 'acceptTask', headerName: 'Assing Change', flex: 1,
      renderCell: (params) => (
        params.row.Status !== "Panding" ? (
          <></>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleAcceptTask(params.row.MainId)}
          >
            Assign Change
          </Button>
        )
      ),
    },
  ];

  const MainRows = TaskId?.data?.data3?.map((el, index) => ({
    id: index + 1,
    MainId: el._id,
    Member: el?.MemberId?.Name,
    AssingBy: el?.AssignedBy?.Name,
    Status: el.Status,
    AssignDate: formatDate(el.AssignDate),
    SubTaskName: el.SubTaskId.SubTaskName
  })) || [];

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
  };

  const handleAcceptTask = (id) => {
    setSelectedTaskId(id);
    setOpenDialog(true);
  };

  const handleMemberChange = (event) => {
    setSelectedMemberId(event.target.value); // Update selected member in separate state
  };

  const handleDialogSubmit = () => {
    AssingsubTaskChangeMemberr(selectedTaskId, selectedMemberId, id);
    setOpenDialog(false);
  };
  return (
    <>
      {
        parsedData?.data?.Role === "owner" ? (
          <>
            <Box sx={{ p: 3 }}>
              <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom color="primary">
                  Main Task Detalis
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card elevation={1} sx={{ borderRadius: 2 }}>
                      <CardContent>
                        <Typography variant="subtitle1">Remark : {TaskId?.data?.data?.Remark}</Typography>
                        <Typography variant="subtitle1">Assign Date : {formatDate(TaskId?.data?.data?.AssignDate)}</Typography>
                        <Typography variant="subtitle1">Assigned By : {TaskId?.data?.data?.AssignedBy?.Name}</Typography>
                        <Typography variant="subtitle1">Company Name : {TaskId?.data?.data?.CompanyId?.CompanyName}</Typography>
                        <Typography variant="subtitle1">Due Date : {formatDate(TaskId?.data?.data?.DueDate)}</Typography>
                        <Typography variant="subtitle1">Description : {TaskId?.data?.data1?.Description}</Typography>
                        <Typography variant="subtitle1">Project : {TaskId?.data?.data1?.Project?.project_name}</Typography>
                        <Typography variant="subtitle1">TastName : {TaskId?.data?.data1?.TastName}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            <Row className='spacer'>
              <Col lg={12}>
                <Paper
                  sx={{
                    height: 400,
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '20px 20px 0 0',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  }}
                >
                  <DataGrid
                    rows={Rows}
                    columns={Columns}
                    pagination
                    pageSize={5}
                    rowsPerPageOptions={[5, 10]}
                    sx={{
                      border: 0,
                      borderRadius: '0px',
                      '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: '20px 20px 0 0',
                        boxShadow: 'none',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#91C7EE',
                        color: '#074C7D',
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      },
                      '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                      },
                      '& .MuiDataGrid-cell': {
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      },
                      '& .MuiDataGrid-columnSeparator': {
                        visibility: 'hidden',
                      },
                    }}
                  />
                </Paper>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {
              parsedData?.data?.Role === "inhouse" ? (
                <Box sx={{ p: 3 }}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h4" gutterBottom color="primary">
                      Task Detalis
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card elevation={1} sx={{ borderRadius: 2 }}>
                          <CardContent>
                            <Typography variant="subtitle1">Remark : {TaskId?.data?.data?.Remark}</Typography>
                            <Typography variant="subtitle1">Assign Date : {formatDate(TaskId?.data?.data?.AssignDate)}</Typography>
                            <Typography variant="subtitle1">Assigned By : {TaskId?.data?.data?.AssignedBy?.Name}</Typography>
                            <Typography variant="subtitle1">Company Name : {TaskId?.data?.data?.CompanyId?.CompanyName}</Typography>
                            <Typography variant="subtitle1">Due Date : {formatDate(TaskId?.data?.data?.DueDate)}</Typography>
                            <Typography variant="subtitle1">Description : {TaskId?.data?.data1?.Description}</Typography>
                            <Typography variant="subtitle1">Project : {TaskId?.data?.data1?.Project?.project_name}</Typography>
                            <Typography variant="subtitle1">TastName : {TaskId?.data?.data1?.TastName}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              ) : (
                <>
                  <Box sx={{ p: 3 }}>
                    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                      <Typography variant="h4" gutterBottom color="primary">
                        Task Detalis
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Card elevation={1} sx={{ borderRadius: 2 }}>
                            <CardContent>
                              <Typography variant="subtitle1">Remark : {TaskId?.data?.data?.Remark}</Typography>
                              <Typography variant="subtitle1">Assign Date : {formatDate(TaskId?.data?.data?.AssignDate)}</Typography>
                              <Typography variant="subtitle1">Assigned By : {TaskId?.data?.data?.AssignedBy?.Name}</Typography>
                              <Typography variant="subtitle1">Company Name : {TaskId?.data?.data?.CompanyId?.CompanyName}</Typography>
                              <Typography variant="subtitle1">Due Date : {formatDate(TaskId?.data?.data?.DueDate)}</Typography>
                              <Typography variant="subtitle1">Description : {TaskId?.data?.data1?.Description}</Typography>
                              <Typography variant="subtitle1">Project : {TaskId?.data?.data1?.Project?.project_name}</Typography>
                              <Typography variant="subtitle1">TastName : {TaskId?.data?.data1?.TastName}</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>

                  <Row className='spacer'>
                    <Col lg={12}>
                      <Paper
                        sx={{
                          height: 400,
                          width: '100%',
                          overflow: 'hidden',
                          borderRadius: '20px 20px 0 0',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        }}
                      >
                        <DataGrid
                          rows={MainRows}
                          columns={MainColumns}
                          pagination
                          pageSize={5}
                          rowsPerPageOptions={[5, 10]}
                          sx={{
                            border: 0,
                            borderRadius: '0px',
                            '& .MuiDataGrid-columnHeaders': {
                              backgroundColor: 'black',
                              color: 'white',
                              borderRadius: '20px 20px 0 0',
                              boxShadow: 'none',
                            },
                            '& .MuiDataGrid-columnHeader': {
                              backgroundColor: '#91C7EE',
                              color: '#074C7D',
                              borderBottom: `1px solid ${theme.palette.divider}`,
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                              fontWeight: 'bold',
                            },
                            '& .MuiDataGrid-cell': {
                              borderBottom: `1px solid ${theme.palette.divider}`,
                            },
                            '& .MuiDataGrid-columnSeparator': {
                              visibility: 'hidden',
                            },
                          }}
                        />
                      </Paper>
                    </Col>
                  </Row>


                  <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
                    <DialogTitle>Change Task Assignment</DialogTitle>
                    <DialogContent>
                      <Grid >
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            sx={{ marginBottom: 3 }}
                          >
                            <InputLabel id="memberId-label">Member ID</InputLabel>
                            <Select
                              labelId="memberId-label"
                              id="MemberId"
                              name="MemberId"
                              value={selectedMemberId} // Use separate state
                              onChange={handleMemberChange} // Handle selection change
                              label="Member ID"
                            >
                              {activeuser?.data?.data?.map((member) => (
                                <MenuItem key={member._id} value={member._id}>{member.Name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDialogClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={handleDialogSubmit} color="secondary">
                        Change
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )
            }
          </>

        )
      }
    </>
  )
}

export default TaskDetailsDetail
