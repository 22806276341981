import { createContext, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types"; // Import PropTypes
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const CustomerContext = createContext();

export const CustomerContextProvider = ({ children }) => {

    const BaseUrl = "https://customerandtask-app.onrender.com"

    const [CustomerData, setCustomerData] = useState();
    
    const AddCustomer = (Data) => {
        axios
            .post(`${BaseUrl}/CustomerSupport/dfghjhgfdsdfgmgvfcdxcv535`, Data)
            .then(function (response) {
                setCustomerData(response)
                if (response?.data.status === "Customer add") {
                    toast.success('Member added successfully!');
                } else {
                    toast.error('Failed to add member. Please try again.');
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const CustomerOn = (el) => {
        axios
            .get(`${BaseUrl}/CustomerSupport/DynamicCustomer_onoff/${el}`)
            .then(function (response) {
                setCustomerData(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const UpdateCustomer = (id, Data) => {
        axios
            .post(`${BaseUrl}/CustomerSupport/safsddfgryrty45645654dfgdfg/${id}`, Data)
            .then(function (response) {
                setCustomerData(response)
                if (response?.data.status === "Customer update") {
                    toast.success('Member update successfully!');
                } else {
                    toast.error('Failed to add member. Please try again.');
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }


    const CustomerDataFun = () => {
        axios
            .get(`${BaseUrl}/CustomerSupport/wsetwertgvsdxsadf4556666`)
            .then(function (response) {
                setCustomerData(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    return (
        <CustomerContext.Provider
            value={{
                AddCustomer: AddCustomer, CustomerDataFun, CustomerData, UpdateCustomer,CustomerOn
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
};

// Add prop-types validation
CustomerContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
