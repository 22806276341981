import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    TextField, MenuItem, Button, FormControl, InputLabel, Select, FormHelperText, Box,
    Typography, Grid, Paper, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, useTheme
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import './Assign_Task_Form.css';
import { TastMasterContext } from '../Context/Task_master';
import { MemberContext } from '../Context/AddMember';
import { CustomerContext } from '../Context/AddCustomer';
import Cookies from 'js-cookie'; // Import js-cookie
import { Col, Row } from 'react-bootstrap';

const Assign_Task_Form = () => {
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { ShowAllTask, AllTask, AssingTask, ShoATask, AssingTShow, AssingTaskChangeMember } = useContext(TastMasterContext);
    const { MemberDataActiveFun, activeuser } = useContext(MemberContext);
    const { CustomerDataFun, CustomerData } = useContext(CustomerContext);
    const loginData = Cookies.get('loginData');
    const parsedData = loginData ? JSON.parse(loginData) : null;
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [selectedMemberId, setSelectedMemberId] = useState('');

    useEffect(() => {
        ShowAllTask();
        MemberDataActiveFun();
        CustomerDataFun();
        ShoATask(parsedData.data._id);
    }, []);

    const formik = useFormik({
        initialValues: {
            TaskId: '',
            MemberId: '',
            CompanyId: '',
            DueDate: '',
            Remark: '',
            AssignedBy: parsedData.data._id
        },
        validationSchema: Yup.object({
            TaskId: Yup.string().required('Task ID is required'),
            MemberId: Yup.string().required('Member ID is required'),
            CompanyId: Yup.string().required('Company ID is required'),
            DueDate: Yup.date()
                .required('End Date is required')
                .min(new Date(), 'End Date cannot be in the past'),
            Remark: Yup.string().required('Remark is required'),
        }),
        onSubmit: (values, { resetForm }) => {
            AssingTask(values);
            resetForm();
        },
    });

    const handleAcceptTask = (id) => {
        setSelectedTaskId(id);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false); // Close the dialog
    };

    const handleDialogSubmit = () => {
        AssingTaskChangeMember(selectedTaskId,selectedMemberId);
        setOpenDialog(false);
    };

    const handleMemberChange = (event) => {
        setSelectedMemberId(event.target.value); // Update selected member in separate state
    };

    const Columns = [
        { field: 'id', headerName: 'Id', flex: 1 },
        { field: 'Member', headerName: 'Member', flex: 1 },
        { field: 'Status', headerName: 'Status', flex: 1 },
        { field: 'CompanyId', headerName: 'Company Name', flex: 1 },
        { field: 'AssignDate', headerName: 'Assign Date', flex: 1 },
        { field: 'DueDate', headerName: 'Due Date', flex: 1 },
        {
            field: 'showTask', headerName: 'Show Full Task', flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`TaskDetailsDetail/${params.row.MainId}`)}
                >
                    Show Task
                </Button>
            ),
        },
        {
            field: 'acceptTask', headerName: 'Accept Task', flex: 1,
            renderCell: (params) => (
                params.row.Status !== "Panding" ? (
                    <></>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleAcceptTask(params.row.MainId)}
                    >
                        Assign Change
                    </Button>
                )
            ),
        },
    ];
    


    const Rows = AssingTShow?.data?.data?.map((el, index) => ({
        id: index + 1,
        MainId: el._id,
        Status:el.Status,
        Member: el?.MemberId?.Name,
        CompanyId: el.CompanyId.CompanyName,
        DueDate: formatDate(el.DueDate),
        AssignDate: formatDate(el.AssignDate),
    })) || [];

    return (
        <>
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    width: '100%',
                    backgroundColor: '#ffffff',
                    padding: 2,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" gutterBottom className='mb-4'>
                    Assign Task Form
                </Typography>

                {/* Remark Field */}
                <Grid container spacing={3}>

                    {/* Task ID Field */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" sx={{ marginBottom: 3 }} error={formik.touched.TaskId && Boolean(formik.errors.TaskId)}>
                            <InputLabel id="taskId-label">Task ID</InputLabel>
                            <Select
                                labelId="taskId-label"
                                id="TaskId"
                                name="TaskId"
                                value={formik.values.TaskId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Task ID"
                            >
                                {AllTask?.data?.data?.map((task) => (
                                    <MenuItem key={task.id} value={task._id}>{task.TastName}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.TaskId && formik.errors.TaskId ? (
                                <FormHelperText>{formik.errors.TaskId}</FormHelperText>
                            ) : null}
                        </FormControl>
                    </Grid>

                    {/* Member ID Field */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" sx={{ marginBottom: 3 }} error={formik.touched.MemberId && Boolean(formik.errors.MemberId)}>
                            <InputLabel id="memberId-label">Member ID</InputLabel>
                            <Select
                                className='h-2'
                                labelId="memberId-label"
                                id="MemberId"
                                name="MemberId"
                                value={formik.values.MemberId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Member ID"
                            >
                                {activeuser?.data?.data?.map((member) => (
                                    <MenuItem key={member._id} value={member._id}>{member.Name}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.MemberId && formik.errors.MemberId ? (
                                <FormHelperText>{formik.errors.MemberId}</FormHelperText>
                            ) : null}
                        </FormControl>
                    </Grid>

                    {/* Company ID Field */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" sx={{ marginBottom: 3 }} error={formik.touched.CompanyId && Boolean(formik.errors.CompanyId)}>
                            <InputLabel id="companyId-label">Company ID</InputLabel>
                            <Select
                                labelId="companyId-label"
                                id="CompanyId"
                                name="CompanyId"
                                value={formik.values.CompanyId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Company ID"
                            >
                                {CustomerData?.data?.data?.map((company) => (
                                    <MenuItem key={company._id} value={company._id}>{company.CompanyName}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.CompanyId && formik.errors.CompanyId ? (
                                <FormHelperText>{formik.errors.CompanyId}</FormHelperText>
                            ) : null}
                        </FormControl>
                    </Grid>

                    {/* Task End Date Field */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="DueDate"
                            name="DueDate"
                            label="Task End Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            value={formik.values.DueDate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.DueDate && Boolean(formik.errors.DueDate)}
                            helperText={formik.touched.DueDate && formik.errors.DueDate}
                            sx={{ marginBottom: 3 }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="Remark"
                            name="Remark"
                            label="Remark"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={formik.values.Remark}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.Remark && Boolean(formik.errors.Remark)}
                            helperText={formik.touched.Remark && formik.errors.Remark}
                            sx={{ marginBottom: 3 }}
                        />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                        <Button color="primary" variant="contained" type="submit">
                            Submit
                        </Button>
                    </Grid>
                </Grid>



            </Box>

            <Row className='spacer'>
                <Col lg={12}>
                    <Paper
                        sx={{
                            height: 400,
                            width: '100%',
                            overflow: 'hidden',
                            borderRadius: '20px 20px 0 0',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        }}
                    >
                        <DataGrid
                            rows={Rows}
                            columns={Columns}
                            pagination
                            pageSize={5}
                            rowsPerPageOptions={[5, 10]}
                            sx={{
                                border: 0,
                                borderRadius: '0px',
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: '20px 20px 0 0',
                                    boxShadow: 'none',
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: '#91C7EE',
                                    color: '#074C7D',
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 'bold',
                                },
                                '& .MuiDataGrid-cell': {
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    visibility: 'hidden',
                                },
                            }}
                        />
                    </Paper>
                </Col>
            </Row>

            {/* Dialog for changing task assignment */}
            <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>Change Task Assignment</DialogTitle>
                <DialogContent>
                    <Grid >
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{ marginBottom: 3 }}
                            >
                                <InputLabel id="memberId-label">Member ID</InputLabel>
                                <Select
                                    labelId="memberId-label"
                                    id="MemberId"
                                    name="MemberId"
                                    value={selectedMemberId} // Use separate state
                                    onChange={handleMemberChange} // Handle selection change
                                    label="Member ID"
                                >
                                    {activeuser?.data?.data?.map((member) => (
                                        <MenuItem key={member._id} value={member._id}>{member.Name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogSubmit} color="secondary">
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Assign_Task_Form;
