import { createContext, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const TastMasterContext = createContext();

export const TastMasterProvider = ({ children }) => {
    
    const BaseUrl = "https://customerandtask-app.onrender.com"

    const [AllTask, setAllTask] = useState();

    const ShowAllTask = () => {
        axios
            .get(`${BaseUrl}/TaskManagement/Taskfind`)
            .then(function (response) {
                setAllTask(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const CreateTask = (data) => {
        axios
            .post(`${BaseUrl}/TaskManagement`, data)
            .then(function (response) {
                setAllTask(response)

            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const [AssingTShow,setAssingTShow] = useState()

    const ShoATask = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/AssingTask/${id}`)
            .then(function (response) {
                setAssingTShow(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    const AssingTask = (data) => {
        axios
            .post(`${BaseUrl}/TaskManagement/AssingTask`, data)
            .then(function (response) {
                setAssingTShow(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const AssingTaskChangeMember = (id,nid) => {
        axios
            .get(`${BaseUrl}/TaskManagement/AssingTaskChangeMember/${id}/${nid}`)
            .then(function (response) {
                setAssingTShow(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }


    const [Tdata, setTdata] = useState()

    const ShowTaskMamner = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/ShowTaskid/${id}`)
            .then(function (response) {
                setTdata(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const[TaskId,setTaskId] = useState()

    const TaskIdfunTione = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/ShowTaskissd/${id}`)
            .then(function (response) {
                setTaskId(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const AccseptTask = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/TaskAccept/${id}`)
            .then(function (response) {
                setTdata(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const ComplateTask = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/ComplateTask/${id}`)
            .then(function (response) {
                setTdata(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const [tasldata,settasldata] = useState()
    const ShowTask = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/TaskfindId/${id}`)
            .then(function (response) {
                settasldata(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const UpdateTask = (id, data) => {
        axios
            .post(`${BaseUrl}/TaskManagement/TaskUpdate/${id}`, data)
            .then(function (response) {
                settasldata(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }


    const[AssingSubTask,setAssingSubTask] = useState()

    const ShowAssingSubTask = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/Show_Task_id/${id}`)
            .then(function (response) {
                setAssingSubTask(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const AssingsubTaskChangeMember = (id,nid,mid) => {
        axios
            .get(`${BaseUrl}/TaskManagement/AssingTaskChangeMember/${id}/${nid}/${mid}`)
            .then(function (response) {
                setAssingSubTask(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const AssingsubTaskChangeMemberr = (id,nid,mid) => {
        axios
            .get(`${BaseUrl}/TaskManagement/AssingSubTaskChangeMemberr/${id}/${nid}/${mid}`)
            .then(function (response) {
                setTaskId(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const[SubTask,setSubTask] = useState()

    const ShowSubTaskFun = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/ShowSubTaskMember/${id}`)
            .then(function (response) {
                setSubTask(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const AccseptSusTask = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/TaskSubAccept/${id}`)
            .then(function (response) {
                setSubTask(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const TaskSubComplate = (id) => {
        axios
            .get(`${BaseUrl}/TaskManagement/TaskSubComplate/${id}`)
            .then(function (response) {
                setSubTask(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const[ComplainData,setComplainData] = useState()

    const ComplainDataFun = (id) => {
        axios
            .get(`${BaseUrl}/ConplainController/asrfeer34645fgdfgdfgwertghdfgh5/${id}`)
            .then(function (response) {
                setComplainData(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const ComplaiComplateFun = (id,sid) => {
        axios
            .get(`${BaseUrl}/ConplainController/asrfeer34645fgdfsdsdgh5/${id}/${sid}`)
            .then(function (response) {
                setComplainData(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }






    return (
        <TastMasterContext.Provider
            value={{
                ShowAllTask, AllTask, CreateTask, UpdateTask: UpdateTask, AssingTask, ShowTaskMamner, Tdata,setTdata, TaskIdfunTione, TaskId, AccseptTask, ComplateTask,tasldata,ShowTask,ShoATask,AssingTShow,
                AssingTaskChangeMember,
                ShowAssingSubTask,AssingSubTask,AssingsubTaskChangeMember,AssingsubTaskChangeMemberr,
                ShowSubTaskFun,SubTask,AccseptSusTask,TaskSubComplate,
                ComplainDataFun,ComplainData,ComplaiComplateFun
            }}
        >
            {children}
        </TastMasterContext.Provider>
    );
};

// Add prop-types validation
TastMasterProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
