import React, { useContext, useEffect } from 'react'
import Table from '../Mui/Table'
import { Col, Row } from 'react-bootstrap'
import { Paper, Button, useMediaQuery } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useTheme } from '@mui/material/styles'
import { TastMasterContext } from '../Context/Task_master'
import Cookies from 'js-cookie'; // Import js-cookie
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomerReq = () => {
  const loginData = Cookies.get('loginData');
  const parsedData = loginData ? JSON.parse(loginData) : null;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {ComplainDataFun, ComplainData, ComplaiComplateFun } = useContext(TastMasterContext);

  const navigate = useNavigate();
// console.log(ComplainData);

  useEffect(() => {
    ComplainDataFun(parsedData.data._id);
  }, []);


  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  const handleShowTask = (id) => {
    navigate(`CustomerrequustDetail/${id}`)
  };

  const handleAcceptTask = (id) => {
    ComplaiComplateFun(id,parsedData.data._id);
  };

  const PandingRows = ComplainData?.data?.Panding?.map((el, index) => ({
    id: index + 1,
    tokan:el?.CallNo,
    MainId: el._id,
    CompanyName: el?.CompanyName?.CompanyName,
    AssignDate: formatDate(el.Date),
    CompanyId: el.CompanyId?.CompanyName,
  })) || [];

  const PandingColumns = [
    { field: 'tokan', headerName: 'Call No.', flex: 1 },
    { field: 'CompanyName', headerName: 'Company Name', flex: 1 },
    { field: 'AssignDate', headerName: 'Assign Date', flex: 1 },
    {
      field: 'showTask', headerName: 'Show Full Task', flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleShowTask(params.row.MainId)}
        >
          Show Task
        </Button>
      ),
    },
    {
      field: 'acceptTask', headerName: 'Complate Task', flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleAcceptTask(params.row.MainId)}
        >
          Complate
        </Button>
      ),
    },
  ];



  // const handleCoplateTask = (id) => {
  //   ComplateTask(id);
  // };


  // const AcceptColumns = [
  //   { field: 'id', headerName: 'Id', flex: 1 },
  //   { field: 'AssignedBy', headerName: 'Assigned By', flex: 1 },
  //   { field: 'CompanyId', headerName: 'Company Name', flex: 1 },
  //   { field: 'AssignDate', headerName: 'Assign Date', flex: 1 },
  //   { field: 'DueDate', headerName: 'Due Date', flex: 1 },
  //   {
  //     field: 'showTask', headerName: 'Show Full Task', flex: 1,
  //     renderCell: (params) => (
  //       <Button
  //         variant="contained"
  //         color="primary"
  //         onClick={() => handleShowTask(params.row.MainId)}
  //       >
  //         Show Task
  //       </Button>
  //     ),
  //   },
  //   {
  //     field: 'acceptTask', headerName: 'Complate Task', flex: 1,
  //     renderCell: (params) => (
  //       <Button
  //         variant="contained"
  //         color="secondary"
  //         onClick={() => handleCoplateTask(params.row.MainId)}
  //       >
  //         Complate Task
  //       </Button>
  //     ),
  //   },
  // ];

  // const AcceptRows = Tdata?.data?.Accept?.map((el, index) => ({
  //   id: index + 1,
  //   MainId: el._id,
  //   AssignedBy: el?.AssignedBy?.Name,
  //   CompanyId: el.CompanyId.CompanyName,
  //   DueDate: formatDate(el.DueDate),
  //   AssignDate: formatDate(el.AssignDate),
  // })) || [];

  const ComplateRows = ComplainData?.data?.Complete?.map((el, index) => ({
    id: index + 1,
    tokan:el?.CallNo,
    MainId: el._id,
    CompanyName: el?.CompanyName?.CompanyName,
    AssignDate: formatDate(el.Date),
    CompanyId: el.CompanyId?.CompanyName,
  })) || [];

  const ComplateColumns = [
    { field: 'tokan', headerName: 'Call No.', flex: 1 },
    { field: 'CompanyName', headerName: 'Company Name', flex: 1 },
    { field: 'AssignDate', headerName: 'Assign Date', flex: 1 },
    {
      field: 'showTask', headerName: 'Show Full Task', flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleShowTask(params.row.MainId)}
        >
          Show Task
        </Button>
      ),
    }
  ];

  return (
    <>
      <div className="containers">
        <div className="title-dashboard text-center spacer">
          <h2>Pending Task</h2>
        </div>

        <Row className='spacer'>
          <Col lg={12}>
            <Paper
              sx={{
                height: 400,
                width: '100%',
                overflow: 'hidden',
                borderRadius: '20px 20px 0 0',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              }}
            >
              <DataGrid
                rows={PandingRows} // Safely access rows
                columns={PandingColumns} // Safely access columns
                pagination
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                sx={{
                  border: 0,
                  borderRadius: '0px',
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'black', // Background color for the entire header row
                    color: 'white', // Text color in the header
                    borderRadius: '20px 20px 0 0',
                    boxShadow: 'none',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#91C7EE', // Ensure column header background color matches
                    color: '#074C7D',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'hidden',
                  },
                }}
                columnVisibilityModel={{
                  ...(isMobile ? { position: false } : {}),
                }}
              />
            </Paper>
          </Col>
        </Row>

        {/* <div className="title-dashboard text-center spacer">
          <h2>Running Task</h2>
        </div>
        <Row className='spacer'>
          <Col lg={12}>
            <Paper
              sx={{
                height: 400,
                width: '100%',
                overflow: 'hidden',
                borderRadius: '20px 20px 0 0',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              }}
            >
              <DataGrid
                rows={AcceptRows} // Safely access rows
                columns={AcceptColumns} // Safely access columns
                pagination
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                sx={{
                  border: 0,
                  borderRadius: '0px',
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'black', // Background color for the entire header row
                    color: 'white', // Text color in the header
                    borderRadius: '20px 20px 0 0',
                    boxShadow: 'none',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#91C7EE', // Ensure column header background color matches
                    color: '#074C7D',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'hidden',
                  },
                }}
                columnVisibilityModel={{
                  ...(isMobile ? { position: false } : {}),
                }}
              />
            </Paper>
          </Col>
        </Row> */}

        <div className="title-dashboard text-center spacer">
          <h2>Complate Task</h2>
        </div>
        <Row className='spacer'>
          <Col lg={12}>
            <Paper
              sx={{
                height: 400,
                width: '100%',
                overflow: 'hidden',
                borderRadius: '20px 20px 0 0',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              }}
            >
              <DataGrid
                rows={ComplateRows} // Safely access rows
                columns={ComplateColumns} // Safely access columns
                pagination
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                sx={{
                  border: 0,
                  borderRadius: '0px',
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'black', // Background color for the entire header row
                    color: 'white', // Text color in the header
                    borderRadius: '20px 20px 0 0',
                    boxShadow: 'none',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#91C7EE', // Ensure column header background color matches
                    color: '#074C7D',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'hidden',
                  },
                }}
                columnVisibilityModel={{
                  ...(isMobile ? { position: false } : {}),
                }}
              />
            </Paper>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  )
}

export default CustomerReq;
