import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { TbLogout } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';
import './sidebar.css';
import Cookies from 'js-cookie'; // Import js-cookie


const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
    const loginData = Cookies.get('loginData');
    const parsedData = loginData ? JSON.parse(loginData) : null;  // Use null if loginData is undefined

    const handleClick = () => {
        Cookies.remove("loginData")
        window.location.reload()
    };
    // console.log(parsedData);

    return (
        <div className={`main-sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
            {/* Toggle button for small and medium screens */}
            <button className="toggle-btn d-lg-none d-block" onClick={toggleSidebar} aria-label="Toggle Sidebar">
                {isSidebarOpen ? <FaTimes /> : <FaBars />}
            </button>

            {/* Logo section */}
            <div className="main-logo">
                <img src={require('../image/logo.png')} alt="Logo" />
            </div>

            {/* Sidebar menu */}
            <div className="sidebar">
                <ul className="menu-sidebar">
                    {
                        parsedData?.data?.Role === "owner" ? (
                            [
                                '/', '/taskmaster', '/subtask', '/assigntask', '/showalltask', '/showallmember', '/addmember', '/addcustomer', '/showcustomer'
                            ].map((path, index) => {
                                const labels = [
                                    'Home', 'Task Master', 'Sub Task', 'Assign Task', 'Show All Task', 'Show All Member', 'Add Member', 'Add Customer', 'Show All Customers'
                                ];
                                return (
                                    <li key={path}>
                                        <NavLink
                                            to={path}
                                            className={({ isActive }) => isActive ? 'active-link' : undefined}
                                        // onClick={handleClick}  // Close the sidebar conditionally based on screen size
                                        >
                                            {labels[index]} <IoIosArrowForward className="menu-icon" />
                                        </NavLink>
                                    </li>
                                );
                            })

                        ) : (
                            parsedData?.data?.Role === "inhouse" ? (
                                [
                                    '/MainTask', "/SubTask", '/CustomerReq'
                                ].map((path, index) => {
                                    const labels = [
                                        'Main Task', "SubTask", 'Customer Request'
                                    ];
                                    return (
                                        <li key={path}>
                                            <NavLink
                                                to={path}
                                                className={({ isActive }) => isActive ? 'active-link' : undefined}
                                            // onClick={handleClick}  // Close the sidebar conditionally based on screen size
                                            >
                                                {labels[index]} <IoIosArrowForward className="menu-icon" />
                                            </NavLink>
                                        </li>
                                    );
                                })
                            ) : (
                                [
                                    '/', "/SubTask", '/CustomerReq', '/taskmaster', '/subtaskmaster', '/assigntask', '/showallmember', '/addmember'
                                ].map((path, index) => {
                                    const labels = [
                                        'Main Task', "SubTask", 'Customer Request', 'Task Master', 'Sub Task Master', 'Assign Task', 'Show All Member', 'Add Member'
                                    ];
                                    return (
                                        <li key={path}>
                                            <NavLink
                                                to={path}
                                                className={({ isActive }) => isActive ? 'active-link' : undefined}
                                            // onClick={handleClick}  // Close the sidebar conditionally based on screen size
                                            >
                                                {labels[index]} <IoIosArrowForward className="menu-icon" />
                                            </NavLink>
                                        </li>
                                    );
                                })
                            )
                        )
                    }
                </ul>
            </div>

            {/* Logout button */}
            <div className="Logout">
                <button onClick={handleClick}><TbLogout /> Logout</button>
            </div>
        </div>
    );
};

export default Sidebar; 
