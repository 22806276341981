import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TastMasterContext } from "../Context/Task_master";
import { Col, ListGroup, Row, Form, Button } from "react-bootstrap";
import UpdateTask from "../Components/UpdateTask";

export const TaskProfile = () => {
  const { tasldata, ShowTask } = useContext(TastMasterContext);
  const { id } = useParams();

  useEffect(() => {
    ShowTask(id);
  }, []);
  const [show, setshow] = useState(false);

  const updateDatafunbut = () => {
    setshow(true);
  };

  return (
    <>
          <div className="title-details">
            <h3>Task Details</h3>
          </div>
          <div className="task-profile-container task-main-details mt-4 containers">
            <Row className="p-lg-5 p-md-4 p-1">
              <Col lg={6}>
                <h4>Task Name:</h4>
                <div className="box-data">
                  <p>{tasldata?.data?.data?.TastName || "N/A"}</p>
                </div>
              </Col>

              <Col lg={6}>
                <h4>Description:</h4>
                <div className="box-data">
                  <p>{tasldata?.data?.data.Description || "N/A"}</p>
                </div>
              </Col>

              <Col lg={6}>
                <h4>Duration:</h4>
                <div className="box-data">
                  <p>{tasldata?.data?.data?.Duration || "N/A"}</p>
                </div>
              </Col>

              <Col lg={6}>
                <h4>Duration Type:</h4>
                <div className="box-data">
                  <p>{tasldata?.data?.data.DurationeType || "N/A"}</p>
                </div>
              </Col>

              <Col lg={6}>
                <h4>Project Name:</h4>
                <div className="box-data">
                  <p>{tasldata?.data?.data.Project?.project_name || "N/A"}</p>
                </div>
              </Col>

              <Col lg={6}>
                <h4>Task Type:</h4>
                <div className="box-data">
                  <p>{tasldata?.data?.data.Type || "N/A"}</p>
                </div>
              </Col>

              {tasldata?.data?.data.Date ? (
                <Col lg={6}>
                  <h4>Task Type:</h4>
                  <div className="box-data">
                    <p>{tasldata?.data?.data.Date || "N/A"}</p>
                  </div>
                </Col>
              ) : (
                <></>
              )}

              {tasldata?.data?.data.Day ? (
                <Col lg={6}>
                  <h4>Task Date:</h4>
                  <div className="box-data">
                    <p>{tasldata?.data?.data.Day || "N/A"}</p>
                  </div>
                </Col>
              ) : (
                <></>
              )}

              {tasldata?.data?.data.Week ? (
                <Col lg={6}>
                  <h4>Task Week:</h4>
                  <div className="box-data">
                    <p>{tasldata?.data?.data.Week || "N/A"}</p>
                  </div>
                </Col>
              ) : (
                <></>
              )}

              <Col lg={6}>
                <h4>Team Members:</h4>
                <div className="box-data">
                  {tasldata?.data?.data.TeamId.length > 0 ? (
                    <ListGroup>
                      {tasldata?.data?.data.TeamId.map((teamMember) => (
                        <ListGroup.Item key={teamMember._id}>
                          {teamMember.Name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <p>No Team Members Assigned</p>
                  )}
                </div>
              </Col>

              <Col lg={6}>
                <h4>Sub-tasks:</h4>
                <div className="box-data">
                  {tasldata?.data?.data.SubTask.length > 0 ? (
                    <ListGroup>
                      {tasldata?.data?.data.SubTask.map((subTask) => (
                        <ListGroup.Item key={subTask._id}>
                          {subTask.SubTaskName}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <p>No Subtasks</p>
                  )}
                </div>
              </Col>
              <Col lg={12}>
                <div className="mt-4 w-100">
                  <Button
                    className="d-inline-block bg-primary text-white border-0 p-2"
                    onClick={updateDatafunbut}
                  >
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          {
            show === true ? (
                <>
                  <UpdateTask/>
                </>
            ) : (
              <>

              </>
            )
          }
    </>
  );
};
