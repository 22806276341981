import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import TaskTable from '../Mui/Table'; // Import the updated table component
import "./Show_All_Task.css";
import { ShowAllTaskContext } from '../Context/ShowAllTask';
import { Paper, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Show_All_Task1 from './Sho_allTast';
import Show_Sub_task from './ShowSubTask';
import SubTaskList from './SubTaskList';

const Show_All_Task = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate(); // Initialize navigate

  const { CompainFun, ComplainData } = useContext(ShowAllTaskContext);
  const [filters, setFilters] = useState({
    tokenName: '',
    companyName: '',
    project: '',
    supportTeam: '',
    status: '',
    todayOnly: false,
    startDate: '',  // Start date for date range filter
    endDate: '',    // End date for date range filter
  });

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  const isToday = (isoDate) => {
    const today = new Date();
    const date = new Date(isoDate);
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  useEffect(() => {
    CompainFun();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters({
      ...filters,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const isDateInRange = (isoDate, startDate, endDate) => {
    const date = new Date(isoDate);
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (startDate && endDate) {
      return date >= start && date <= end;
    }
    return true;
  };

  // Extract unique options for dropdowns
  const companyNameOptions = [...new Set(ComplainData?.data?.data?.map(el => el.CompanyName?.CompanyName))];
  const projectOptions = [...new Set(ComplainData?.data?.data?.map(el => el.Project?.project_name))];
  const supportTeamOptions = [...new Set(ComplainData?.data?.data?.map(el => el.SuportTeamId?.Name))];
  const statusOptions = [...new Set(ComplainData?.data?.data?.map(el => el.Status))];

  const ComplainColumns = [
    { field: 'tokenName', headerName: 'Token Name', flex: 1 },
    { field: 'companyname', headerName: 'Company Name', flex: 1 },
    { field: 'Project', headerName: 'Project', flex: 1 },
    { field: 'SuportTeamId', headerName: 'Support Team', flex: 1 },
    { field: 'Date', headerName: 'Date', flex: 1 },
    { field: 'Status', headerName: 'Status', flex: 1 },
  ];

  const filteredRows = ComplainData?.data?.data
    ?.filter((el) => {
      const { tokenName, companyName, project, supportTeam, status, todayOnly, startDate, endDate } = filters;

      const matchesTokenName = !tokenName || el.CallNo.toLowerCase().includes(tokenName.toLowerCase());
      const matchesCompanyName = !companyName || el.CompanyName?.CompanyName === companyName;
      const matchesProject = !project || el.Project?.project_name === project;
      const matchesSupportTeam = !supportTeam || el.SuportTeamId?.Name === supportTeam;
      const matchesStatus = !status || el.Status === status;
      const matchesToday = !todayOnly || isToday(el.Date);
      const matchesDateRange = isDateInRange(el.Date, startDate, endDate); // Check if the date falls in the range

      return matchesTokenName && matchesCompanyName && matchesProject && matchesSupportTeam && matchesStatus && matchesToday && matchesDateRange;
    })
    .map((el, index) => ({
      id: index + 1,
      tokenName: el.CallNo,
      companyname: el.CompanyName?.CompanyName,
      Project: el.Project?.project_name,
      SuportTeamId: el.SuportTeamId?.Name,
      Date: formatDate(el.Date),
      MainId: el._id, // Include MainId for navigation
      Status: el.Status,
    }))
    .reverse();

  const handleRowClick = (params) => {
    navigate(`/CustomerrequustDetail/${params.row.MainId}`); // Navigate to target page with MainId
  };

  return (
    <div className="containers">
      
      <div className="add-text">
        <h3 className='mt-1'>Show All Customer Request</h3>
      </div>
      {/* Filter Form */}
      <Form className="filter-form bg-filter-color rounded-3 mb-3">
        <Row className='p-4'>
          <Col lg={4}>
            <Form.Group controlId="tokenName">
              <Form.Label>Token Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Token Name"
                name="tokenName"
                value={filters.tokenName}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="companyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Select
                name="companyName"
                value={filters.companyName}
                onChange={handleFilterChange}
              >
                <option value="">All Companies</option>
                {companyNameOptions.map((name, idx) => (
                  <option key={idx} value={name}>{name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="project">
              <Form.Label>Project</Form.Label>
              <Form.Select
                name="project"
                value={filters.project}
                onChange={handleFilterChange}
              >
                <option value="">All Projects</option>
                {projectOptions.map((project, idx) => (
                  <option key={idx} value={project}>{project}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="supportTeam">
              <Form.Label>Support Team</Form.Label>
              <Form.Select
                name="supportTeam"
                value={filters.supportTeam}
                onChange={handleFilterChange}
              >
                <option value="">All Support Teams</option>
                {supportTeamOptions.map((team, idx) => (
                  <option key={idx} value={team}>{team}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
              >
                <option value="">All Statuses</option>
                {statusOptions.map((status, idx) => (
                  <option key={idx} value={status}>{status}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {/* Start Date Filter */}
          <Col lg={4}>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          {/* End Date Filter */}
          <Col lg={4}>
            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Check
              type="checkbox"
              label="Show Today Only"
              name="todayOnly"
              checked={filters.todayOnly}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
      </Form>

      {/* Data Grid */}
      <Row className='spacer'>
        <Col lg={12}>
          <Paper
            sx={{
              height: 400,
              width: '100%',
              overflow: 'hidden',
              borderRadius: '20px 20px 0 0',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}
          >
            <DataGrid
              rows={filteredRows} // Safely access rows
              columns={ComplainColumns} // Safely access columns
              pagination
              pageSize={5}
              rowsPerPageOptions={[5, 10]}
              onRowClick={handleRowClick} // Attach row click handler
              sx={{
                border: 0,
                borderRadius: '0px',
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'black', // Background color for the entire header row
                  color: 'white', // Text color in the header
                  borderRadius: '20px 20px 0 0',
                  boxShadow: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#91C7EE', // Ensure column header background color matches
                  color: '#074C7D',
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
                '& .MuiDataGrid-columnSeparator': {
                  visibility: 'hidden',
                },
              }}
              columnVisibilityModel={{
                ...(isMobile ? { position: false } : {}),
              }}
            />
          </Paper>
        </Col>
      </Row>
      <Show_All_Task1 />
      <SubTaskList />
    </div>
  );
};

export default Show_All_Task;
