import React, { useContext, useState } from 'react';
import './login.css';
import { AuthContext } from '../Context/Auth';
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer
import Cookies from 'js-cookie'; // Import js-cookie


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const { Login } = useContext(AuthContext);


    const loginData = Cookies.get('loginData');
    const parsedData = loginData ? JSON.parse(loginData) : null;  // Use null if loginData is undefined
    // console.log(parsedData);code


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!username || !password) {
            toast.error('Username and Password are required', {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        } else {
            Login(username, password);
        }

    };

    return (
        <div className="login-container d-flex align-items-center justify-content-center vh-100">
            <div className="bg-image">
                <div className="bg-color">
                    <div className="image-logo">
                        <img src={require('../image/logo.png')} alt="" />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label className='position-relative'>Email</label>
                            <input
                                type="text"

                                placeholder="Enter Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className='position-relative'>Password</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        {error && <p className="error-message">{error}</p>}
                        <button type="submit">Log in</button>
                    </form>
                </div>
            </div>
            <ToastContainer /> {/* Add ToastContainer here */}
        </div>
    );
};

export default Login;   
