import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';
import Table from '../Mui/Table'; // Ensure this path is correct
import './Show_All_Member.css';
import { CustomerContext } from '../Context/AddCustomer';

// Custom style for the MUI modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const HomeCustomer = () => {
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [open, setOpen] = useState(false);
    const { CustomerDataFun, CustomerData } = useContext(CustomerContext);

    const [filter, setFilter] = useState('All'); // State for filtering data

    useEffect(() => {
        CustomerDataFun();
    }, []);

    // Columns for the table
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'CompanyName', headerName: 'Company Name', flex: 1 },
        { field: 'MobileNumber', headerName: 'Mobile Number', flex: 1 },
        { field: 'Profile', headerName: 'Profile', flex: 1 },
        {
            field: 'profileLink', headerName: 'Profile', flex: 1, renderCell: (params) => (
                <a href={params.value} onClick={(e) => handleProfileClick(e, params.row)}>
                    View Profile
                </a>
            )
        }
    ];

    const rows = CustomerData?.data?.data?.map((el, index) => ({
        id: el.id || index + 1,
        CompanyName: el.CompanyName,
        MobileNumber: el.ContactNo,
        MainId: el._id,
        Profile: el.profile,
        Email: el.Email,
    })) || [];

        // Calculate counts for each filter category
        const totalMembers = rows.length;
        const activeMembersCount = rows.filter((row) => row.Profile === 'On').length;
        const nonActiveMembersCount = rows.filter((row) => row.Profile === 'Off').length;

    // Apply filter to the customer data based on selected filter (All, Active, Nonactive)
    const filteredRows = rows.filter((row) => {
        if (filter === 'All') return true;
        if (filter === 'Active') return row.Profile === 'On';
        if (filter === 'NonActive') return row.Profile === 'Off';
        return true;
    });

    // Handle profile link click
    const handleProfileClick = (event, rowData) => {
        event.preventDefault();
        setSelectedProfile(rowData); // Set the selected profile data
        setOpen(true); // Open the modal when profile is clicked
    };

    const handleClose = () => setOpen(false); // Close modal function

    // Handle filter change when a button is clicked
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter); // Update filter state
    };

    return (
        <div>
            <Row>
                <div className="add-text mt-2 containers">
                    <h3>Show All Customer</h3>
                </div>
            </Row>

            <div className="containers">

                <Row style={{ background: "#91C7EE", padding: "20px", borderRadius: "10px" }} className="mb-3">
                    <Col>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color={filter === 'All' ? 'primary' : 'default'}
                                    onClick={() => handleFilterChange('All')}
                                >
                                    All Customer ({totalMembers})
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color={filter === 'Active' ? 'primary' : 'default'}
                                    onClick={() => handleFilterChange('Active')}
                                >
                                    Active Customer ({activeMembersCount})
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color={filter === 'NonActive' ? 'primary' : 'default'}
                                    onClick={() => handleFilterChange('NonActive')}
                                >
                                    Non-Active Customer ({nonActiveMembersCount})
                                </Button>
                            </Grid>
                        </Grid>
                    </Col>
                </Row>
                
                <Row className="containers spacer g-xl-3 g-lg-2">
                    <Col lg={12}>
                        <Row className="g-5">
                            <Col lg={12}>
                                <Table columns={columns} rows={filteredRows} />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Modal for showing profile details */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Profile Details
                        </Typography>
                        {selectedProfile && (
                            <div className="profile-details">
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <p><strong>Name:</strong> {selectedProfile.CompanyName}</p>
                                    <p><strong>Email:</strong> {selectedProfile.Email}</p>
                                    <p><strong>Mobile Number:</strong> {selectedProfile.MobileNumber}</p>
                                </Typography>
                            </div>
                        )}
                        <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
                            Close
                        </Button>
                    </Box>
                </Modal>
            </div>
        </div>

    );
}

export default HomeCustomer;
