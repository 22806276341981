import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Paper, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { ShowAllTaskContext } from '../Context/ShowAllTask';

const Show_All_Task1 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate(); // Initialize navigate

  const { TaskShowFun, TaskData } = useContext(ShowAllTaskContext);

  const [filters, setFilters] = useState({
    status: '',
    Assignby: '',
    assignedTo: '',  // New filter for Assigned To
    todayOnly: false,
    startDate: '',  // New start date filter
    endDate: '',    // New end date filter
  });

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  const isToday = (isoDate) => {
    const today = new Date();
    const date = new Date(isoDate);
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  const isBetweenDates = (isoDate) => {
    const date = new Date(isoDate);
    const startDate = filters.startDate ? new Date(filters.startDate) : null;
    const endDate = filters.endDate ? new Date(filters.endDate) : null;

    if (startDate && endDate) {
      return date >= startDate && date <= endDate;
    }
    return true; // If no date range selected, return true for all dates
  };

  useEffect(() => {
    TaskShowFun();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters({
      ...filters,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Extract unique options for dropdowns
  const TaskIdOptions = [...new Set(TaskData?.data?.data?.map(el => el.TaskId?.TastName))];
  const statusOptions = [...new Set(TaskData?.data?.data?.map(el => el.Status))];
  const AssigntoOptions = [...new Set(TaskData?.data?.data?.map(el => el?.MemberId?.Name))];
  const AssingbyOptions = [...new Set(TaskData?.data?.data?.map(el => el?.AssignedBy?.Name))];

  const ComplainColumns = [
    { field: 'TaskId', headerName: 'Task Name', flex: 1 },
    { field: 'Date', headerName: 'Date', flex: 1 },
    { field: 'DueDate', headerName: 'Due Date', flex: 1 },
    { field: 'AssignTo', headerName: 'Assign To', flex: 1 },
    { field: 'AssignBy', headerName: 'Assign By', flex: 1 },
    { field: 'Status', headerName: 'Status', flex: 1 },
  ];

  const filteredRows = TaskData?.data?.data
    .map((el, index) => ({
      id: index + 1,
      MainId: el._id,
      TaskId: el?.TaskId?.TastName,
      Date: formatDate(el.AssignDate),
      DueDate: formatDate(el.DueDate),
      AssignTo: el?.MemberId?.Name,
      AssignBy: el?.AssignedBy?.Name,
      Status: el.Status,
      AssignDate: el.AssignDate, // Keep original date for filtering
    }))
    .filter(row => {
      const statusMatch = !filters.status || row.Status === filters.status;
      const assignedByMatch = !filters.Assignby || row.AssignBy === filters.Assignby;
      const assignedToMatch = !filters.assignedTo || row.AssignTo === filters.assignedTo;
      const todayMatch = !filters.todayOnly || isToday(row.AssignDate);
      const dateRangeMatch = isBetweenDates(row.AssignDate); // Check if date is between selected range

      return statusMatch && assignedByMatch && assignedToMatch && todayMatch && dateRangeMatch;
    })
    .reverse();

  const handleRowClick = (params) => {
    navigate(`/showalltask/TaskDetailsDetail/${params.row.MainId}`); // Navigate to target page with MainId
  };

  return (
    <>
      <div className="add-text">
        <h3 className='mt-1'>Show Main Task</h3>
      </div>
      {/* Filter Form */}
      <Form className="filter-form bg-filter-color rounded-3 mb-3">
        <Row className='p-4'>
          <Col lg={4}>
            <Form.Group controlId="companyName">
              <Form.Label>Task Name</Form.Label>
              <Form.Select
                name="companyName"
                value={filters.companyName}
                onChange={handleFilterChange}
              >
                <option value="">All Task Name</option>
                {TaskIdOptions.map((name, idx) => (
                  <option key={idx} value={name}>{name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="assignBy">
              <Form.Label>Assign By</Form.Label>
              <Form.Select
                name="Assignby"
                value={filters.Assignby}
                onChange={handleFilterChange}
              >
                <option value="">All Assigned By</option>
                {AssingbyOptions.map((project, idx) => (
                  <option key={idx} value={project}>{project}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="assignedTo">
              <Form.Label>Assigned To</Form.Label>
              <Form.Select
                name="assignedTo"
                value={filters.assignedTo} // New filter for Assigned To
                onChange={handleFilterChange}
              >
                <option value="">All Assigned To</option>
                {AssigntoOptions.map((team, idx) => (
                  <option key={idx} value={team}>{team}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
              >
                <option value="">All Statuses</option>
                {statusOptions.map((status, idx) => (
                  <option key={idx} value={status}>{status}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {/* Start Date */}
          <Col lg={4}>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          {/* End Date */}
          <Col lg={4}>
            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Check
              type="checkbox"
              label="Show Today Only"
              name="todayOnly"
              checked={filters.todayOnly}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
      </Form>

      <Row className='spacer'>
        <Col lg={12}>
          <Paper
            sx={{
              height: 400,
              width: '100%',
              overflow: 'hidden',
              borderRadius: '20px 20px 0 0',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}
          >
            <DataGrid
              rows={filteredRows} // Safely access rows
              columns={ComplainColumns} // Safely access columns
              pagination
              pageSize={5}
              rowsPerPageOptions={[5, 10]}
              onRowClick={handleRowClick} // Attach row click handler
              sx={{
                border: 0,
                borderRadius: '0px',
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'black', // Background color for the entire header row
                  color: 'white', // Text color in the header
                  borderRadius: '20px 20px 0 0',
                  boxShadow: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#91C7EE', // Ensure column header background color matches
                  color: '#074C7D',
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
              }}
            />
          </Paper>
        </Col>
      </Row>
    </>
  );
};

export default Show_All_Task1;
