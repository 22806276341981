import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { Sub_taskContext } from '../Context/Sub_task';
import { useParams } from 'react-router-dom';
import { MemberContext } from '../Context/AddMember';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as MUIButton } from '@mui/material';

const EditSubTask = () => {
    const { SubTastDelete, UpdateSubTask, SubTaskid, SubTaskFun } = useContext(Sub_taskContext);
    const { MemberDataActiveFun, activeuser } = useContext(MemberContext);
    const { id } = useParams();
    const [showConfirmModal, setShowConfirmModal] = useState(false); // For confirmation modal visibility


    const [members, setMembers] = useState([]);
    const [selectedMemberId, setSelectedMemberId] = useState('');
    const [subTaskName, setSubTaskName] = useState('');

    useEffect(() => {
        MemberDataActiveFun();
        SubTaskid(id);
    }, []);

    useEffect(() => {
        const initialMembers = SubTaskFun?.data?.data?.TeamId || [];
        // console.log('Initial Members:', initialMembers);
        setMembers(initialMembers.map(el => ({ id: el._id, name: el.Name })));
        setSubTaskName(SubTaskFun?.data?.data?.SubTaskName || '');
    }, [SubTaskFun]);

    const handleSelectChange = (e) => {
        setSelectedMemberId(e.target.value);
    };

    const handleAddMember = () => {
        const selectedMember = activeuser?.data?.data.find(member => member._id === selectedMemberId);
        if (selectedMember && !members.some(member => member.id === selectedMember._id)) {
            setMembers([...members, { id: selectedMember._id, name: selectedMember.Name }]);
            setSelectedMemberId('');
        } else {
            toast.error('Member already added or not selected!');
        }
    };

    const handleRemoveMember = (id) => {
        // Remove member by filtering out the member with the specified ID
        setMembers(members.filter(member => member.id !== id));
        toast.success('Member removed successfully!');
    };

    const handleUpdate = () => {
        const memberIds = members.map(member => member.id);
        UpdateSubTask(id,subTaskName,memberIds)
    };

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('text/plain', index);
    };

    const handleDrop = (e, dropIndex) => {
        e.preventDefault();
        const dragIndex = e.dataTransfer.getData('text/plain');
        const updatedMembers = [...members];
        if (dragIndex !== dropIndex.toString()) {
            const [movedMember] = updatedMembers.splice(dragIndex, 1);
            updatedMembers.splice(dropIndex, 0, movedMember);
            setMembers(updatedMembers);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDeleteTask = () => {
        SubTastDelete(id);
        setShowConfirmModal(false); // Close the confirmation dialog
    };

    const handleOpenConfirmDialog = () => {
        setShowConfirmModal(true); // Open confirmation dialog
    };

    const handleCloseConfirmDialog = () => {
        setShowConfirmModal(false); // Close confirmation dialog
    };

    return (
        <>
            <Col xs={12}>
                <div className="add-text mt-2 col-12 containers">
                    <h3>Sub Task Master</h3>
                </div>
            </Col>
            <Container fluid="md">
                <Row className="g-sm-5 bg-white mt-5">
                    <Col lg={6} md={12} sm={12}>
                        <div className="form-container p-3 w-100">
                            <h3 className="heading">Sub Task Name</h3>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    value={subTaskName}
                                    onChange={(e) => setSubTaskName(e.target.value)}
                                />
                            </Form.Group>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <div className="form-container p-3 w-100">
                            <h3 className="heading">Add Member</h3>
                            <Form.Group>
                                <Form.Select value={selectedMemberId} onChange={handleSelectChange}>
                                    <option value="">Select a member</option>
                                    {activeuser?.data?.data?.map(member => (
                                        <option key={member._id} value={member._id}>
                                            {member.Name}
                                        </option>
                                    ))}
                                </Form.Select>

                                <Row className="justify-content-end">
                                    <Col xs="auto">
                                        <Button onClick={handleAddMember} className="mt-3">Add</Button>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </div>
                        <div className="form-container p-3 w-100">
                            <h3 className="heading">Sub Task Members</h3>
                            <div style={{ border: '1px dashed gray', padding: '10px', minHeight: '50px' }}>
                                {members.length > 0 ? (
                                    members.map((member, index) => (
                                        <div
                                            key={member.id}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDrop={(e) => handleDrop(e, index)}
                                            onDragOver={handleDragOver}
                                            style={{
                                                padding: '8px',
                                                margin: '4px 0',
                                                border: '1px solid #ccc',
                                                cursor: 'grab',
                                                backgroundColor: '#f9f9f9',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            {member.name}
                                            <Button variant="danger" size="sm" onClick={() => handleRemoveMember(member.id)}>Remove</Button>
                                        </div>
                                    ))
                                ) : (
                                    <p>No members available.</p>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Row>
                        <Col xs={12}>
                            <Row className="justify-content-end p-4">
                                <Col xs="auto">
                                    <Button variant="danger" onClick={handleOpenConfirmDialog} className="mt-3">Remove Task</Button>
                                </Col>
                                <Col xs="auto">
                                    <Button onClick={handleUpdate} className="mt-3">Update</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
                <Dialog
                    open={showConfirmModal}
                    onClose={handleCloseConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this task?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the Sub Task?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MUIButton onClick={handleCloseConfirmDialog} color="primary">
                            Cancel
                        </MUIButton>
                        <MUIButton onClick={handleDeleteTask} color="secondary" autoFocus>
                            Delete
                        </MUIButton>
                    </DialogActions>
                </Dialog>
                <ToastContainer />

            </Container>
        </>
    );
};

export default EditSubTask;
