import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Paper, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { TastMasterContext } from '../Context/Task_master';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const HomeTaskMaste = () => {
    const { ShowAllTask, AllTask } = useContext(TastMasterContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        ShowAllTask();
    }, []);

    const navigate = useNavigate();
    const [filters, setFilters] = useState({
        todayOnly: false,
        TaskType: "",
        ProjectName: "",
        startDate: '',
        endDate: '',
    });

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters({
            ...filters,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const TaskType = [...new Set(AllTask?.data?.data?.map(el => el.Type))];
    const ProjectName = [...new Set(AllTask?.data?.data?.map(el => el?.Project?.project_name))];

    // Filtering logic
    const filteredData = AllTask?.data?.data?.filter((el) => {
        const taskDate = new Date(el.GeneratedDate);
        const startDate = filters.startDate ? new Date(filters.startDate) : null;
        const endDate = filters.endDate ? new Date(filters.endDate) : null;

        let isValid = true;

        // Task type filter
        if (filters.TaskType && el.Type !== filters.TaskType) {
            isValid = false;
        }

        // Project name filter
        if (filters.ProjectName && el.Project.project_name !== filters.ProjectName) {
            isValid = false;
        }

        // Start date filter
        if (startDate && taskDate < startDate) {
            isValid = false;
        }

        // End date filter
        if (endDate && taskDate > endDate) {
            isValid = false;
        }

        // Today's task filter
        if (filters.todayOnly) {
            const today = new Date();
            const isToday = taskDate.toDateString() === today.toDateString();
            if (!isToday) isValid = false;
        }

        return isValid;
    });

    const rows = filteredData?.map((el, index) => ({
        id: index + 1,
        MainId: el?._id,
        TaskName: el?.TastName,
        Duration: ` ${el?.Duration}-${el?.DurationeType}`,
        ProjectName: el?.Project?.project_name,
        Description: el?.Description,
        GeneratedDate: el?.GeneratedDate,
        Type: el?.Type,
        SubTask: el?.SubTask?.join(', '),
    }));
    // console.log(rows);
    

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'TaskName', headerName: 'Task Name', flex: 1 },
        { field: 'Type', headerName: 'Task Type', flex: 1 },
        { field: 'Duration', headerName: 'Duration', flex: 1 },
        { field: 'ProjectName', headerName: 'Project Name', flex: 1 },
        {
            field: 'Profile',
            headerName: 'View Details',
            flex: 1,
            renderCell: (params) => (
                <Button onClick={() => handleViewProfile(params.row.MainId)}>
                    View Details
                </Button>
            ),
        },
    ];

    const handleViewProfile = (taskId) => {
        navigate(`/task-profile/${taskId}`);
    };

    return (
        <>
            <div className="add-text">
                <h3 className="mt-1">Show Task Master</h3>
            </div>

            <Form className="filter-form bg-filter-color rounded-3 mb-3">
                <Row className="p-4">
                    <Col lg={4}>
                        <Form.Group controlId="TaskType">
                            <Form.Label>Task Type</Form.Label>
                            <Form.Select
                                name="TaskType"
                                value={filters.TaskType}
                                onChange={handleFilterChange}
                            >
                                <option value="">All Task Type</option>
                                {TaskType.map((name, idx) => (
                                    <option key={idx} value={name}>
                                        {name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="ProjectName">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Select
                                name="ProjectName"
                                value={filters.ProjectName}
                                onChange={handleFilterChange}
                            >
                                <option value="">All Project Name</option>
                                {ProjectName.map((project, idx) => (
                                    <option key={idx} value={project}>
                                        {project}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="startDate">
                            <Form.Label>Generated Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="startDate"
                                value={filters.startDate}
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="endDate">
                            <Form.Label>Generated End Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="endDate"
                                value={filters.endDate}
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Check
                            type="checkbox"
                            label="Show Today Only"
                            name="todayOnly"
                            checked={filters.todayOnly}
                            onChange={handleFilterChange}
                        />
                    </Col>
                </Row>
            </Form>

            <Row className='spacer'>

                <Paper
                    sx={{
                        height: 400,
                        width: '100%',
                        overflow: 'hidden',
                        borderRadius: '20px 20px 0 0',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    }}
                >
                    <DataGrid
                        rows={rows} // Safely access rows
                        columns={columns} // Safely access columns
                        pagination
                        pageSize={5}
                        rowsPerPageOptions={[5, 10]}
                        sx={{
                            border: 0,
                            borderRadius: '0px',
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: 'black', // Background color for the entire header row
                                color: 'white', // Text color in the header
                                borderRadius: '20px 20px 0 0',
                                boxShadow: 'none',
                            },
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#91C7EE', // Ensure column header background color matches
                                color: '#074C7D',
                                borderBottom: ` 1px solid ${theme.palette.divider}`,
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: `1px solid ${theme.palette.divider}`,
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                        }}
                        columnVisibilityModel={{
                            ...(isMobile ? { position: false } : {}),
                        }}
                    />
                </Paper>
            </Row>
        </>
    );
};

export default HomeTaskMaste;
