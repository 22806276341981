import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Modal, Box, Typography, Button } from '@mui/material'; // Import Avatar for photo display
import Table from '../Mui/Table'; // Ensure this path is correct
import './Show_All_Member.css';
import { CustomerContext } from '../Context/AddCustomer';

// Custom style for the MUI modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Show_All_Customer = () => {
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [open, setOpen] = useState(false);
  const { CustomerDataFun, CustomerData, CustomerOn } = useContext(CustomerContext);

  useEffect(() => {
    CustomerDataFun();
  }, []);


  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'CompanyName', headerName: 'Company Name', flex: 1 },
    { field: 'MobileNumber', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'profile', headerName: 'Profile Mode', flex: 1, renderCell: (params) => (
        <Button
          onClick={(e) => handleOnClick(e, params.row.MainId)}
          variant="contained"
          style={{
            backgroundColor: params.row.Profile === 'On' ? 'blue' : 'red',
            color: 'white', // Ensure the text color is readable
          }}
        >
          {params.row.Profile}
        </Button>
      )
    }
    ,
    {
      field: 'profileLink', headerName: 'Profile', flex: 1, renderCell: (params) => (
        <a href={params.value} onClick={(e) => handleProfileClick(e, params.row)}>
          View Profile
        </a>
      )
    },
  ];
  // console.log(CustomerData);

  const rows = CustomerData?.data?.data?.map((el, index) => ({ // Use CustomerData to map rows
    id: el.id || index + 1,
    CompanyName: el.CompanyName,
    MobileNumber: el.ContactNo,
    Profile: el.profile,
    MainId: el._id,
    Email: el.Email,
  })) || [];

  const handleProfileClick = (event, rowData) => {
    event.preventDefault();
    setSelectedProfile(rowData); // Set the selected profile data
    setOpen(true); // Open the modal when profile is clicked
  };

  const handleOnClick = (event, rowData) => {
    event.preventDefault();
    CustomerOn(rowData);
  };

  const handleClose = () => setOpen(false); // Close modal function

  return (
    <div>
      <Row>
        <div className="add-text mt-2 containers">
          <h3>Show All Customer</h3>
        </div>
      </Row>
      <Row className="containers spacer g-xl-3 g-lg-2">
        <Col lg={12}>
          <Row className="g-5">
            <Col lg={12}>
              <Table columns={columns} rows={rows} />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Modal for showing profile details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Profile Details
          </Typography>
          {selectedProfile && (
            <div className="profile-details">
              {/* <Avatar
                alt={selectedProfile.name}
                src={selectedProfile.photo}
                sx={{ width: 100, height: 100, mb: 2 }}
              /> */}
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <p><strong>Name:</strong> {selectedProfile.CompanyName}</p>
                <p><strong>Email:</strong> {selectedProfile.Email}</p>
                <p><strong>Mobile Number:</strong> {selectedProfile.MobileNumber}</p>
                {/* <p><strong>Profile Link:</strong> <a href={selectedProfile.profileLink}>View Full Profile</a></p> */}
              </Typography>
            </div>
          )}
          <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default Show_All_Customer;
