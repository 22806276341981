import { Box, Grid, Paper } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TextField, MenuItem, Button, FormControl, InputLabel, Select, FormHelperText, Typography } from '@mui/material';
import { Row } from 'react-bootstrap';
import { Sub_taskContext } from '../Context/Sub_task';
import { TastMasterContext } from '../Context/Task_master';
import { MemberContext } from '../Context/AddMember';
import { DataGrid } from '@mui/x-data-grid';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const Task_Form = () => {
    const { SubTaskData, SubTaskDataFun, ProjectData, ProjectDataFun } = useContext(Sub_taskContext);
    const { ShowAllTask, AllTask, CreateTask } = useContext(TastMasterContext);
    const { MemberDataActiveFun, activeuser } = useContext(MemberContext);

    const [formValues, setFormValues] = useState({
        Description: '',
        DurationeType: '',
        TastName: '',
        Duration: '',
        Project: '',
        SubTask: [],
        TeamId: [],
        Type: '',
        Week: '',
        Day: '',
        Date: ''
    });
    
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (!SubTaskData || !ProjectData) {
            SubTaskDataFun();
            ProjectDataFun();
            ShowAllTask();
            MemberDataActiveFun();
        }
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'TaskName', headerName: 'Task Name', flex: 1 },
        { field: 'Duration', headerName: 'Duration', flex: 1 },
        { field: 'ProjectName', headerName: 'Project Name', flex: 1 },
        {
            field: 'Profile',
            headerName: 'View Details',
            flex: 1,
            renderCell: (params) => (
                <Button

                    onClick={() => handleViewProfile(params.row.MainId)}
                >
                    View Details
                </Button>
            ),
        },
    ];
    const navigate = useNavigate();

    const handleViewProfile = (taskId) => {
        navigate(`/task-profile/${taskId}`);
    };

    const rows = AllTask?.data?.data?.map((el, index) => ({
        id: index + 1,
        MainId: el._id,
        TaskName: el.TastName,
        Duration: ` ${el.Duration}-${el.DurationeType}`,
        ProjectName: el?.Project?.project_name,
        Description: el.Description,
        SubTask: el.SubTask?.join(', '),
    }));

    const TASKS = SubTaskData?.data?.data?.map(el => ({
        SubTaskId: el._id,
        SubTaskName: el.SubTaskName,
    })) || [];

    const PROJECTS = ProjectData?.data?.data?.map(el => ({
        project_id: el._id,
        project_name: el.project_name,
    })) || [];

    const Member = activeuser?.data?.data?.map(el => ({
        Name_id: el._id,
        Name_name: el.Name,
    })) || [];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleMultiSelectChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            CreateTask(formValues);
            setFormValues({
                Description: '',
                DurationeType: '',
                TastName: '',
                Duration: '',
                Project: '',
                SubTask: [],
                TeamId: [],
                Type: '',
                Week: '',
                Day: '',
                Date: ''
            });
        } else {
            setFormErrors(errors);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formValues.Description) {
            errors.Description = 'Description is required';
        }
        if (!formValues.DurationeType) {
            errors.DurationeType = 'DurationeType is required';
        }
        if (!formValues.TastName) {
            errors.TastName = 'Task Name is required';
        }
        if (!formValues.Duration || formValues.Duration <= 0) {
            errors.Duration = 'Duration must be a positive number';
        }
        if (!formValues.Project) {
            errors.Project = 'Project Name is required';
        }
        if (!formValues.TeamId.length) {
            errors.TeamId = 'At least one member must be selected';
        }
        if (!formValues.Type) {
            errors.Type = 'Task Type is required';
        }
        return errors;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Ensure columns and rows are valid arrays
    if (!Array.isArray(columns) || !Array.isArray(rows)) {
        return <div>Error: Invalid table data</div>;
    }

    return (
        <div className='containers'>
            <div className="add-text mt-3">
                <h3>Task Master</h3>
            </div>
            <Row className='spacer'>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        width: '100%',
                        backgroundColor: '#ffffff',
                        padding: 2,

                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" gutterBottom className='mb-4'>
                        Assign Task Form
                    </Typography>

                    <Grid container spacing={3}>
                        {/* Description Field */}
                       

                        {/* Task Name Field */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="TastName"
                                name="TastName"
                                label="Task Name"
                                variant="outlined"
                                multiline
                                rows={1}
                                value={formValues.TastName}
                                onChange={handleChange}
                                error={!!formErrors.TastName}
                                helperText={formErrors.TastName}
                                sx={{ marginBottom: 3 }}
                            />
                        </Grid>

                        {/* Duration Field */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                id="Duration"
                                name="Duration"
                                label="Duration"
                                type="number"
                                variant="outlined"
                                value={formValues.Duration}
                                onChange={handleChange}
                                error={!!formErrors.Duration}
                                helperText={formErrors.Duration}
                                sx={{ marginBottom: 3 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <TextField
                                select
                                fullWidth
                                id="DurationeType"
                                name="DurationeType"
                                label="Duration Type"
                                value={formValues.DurationeType}
                                onChange={handleChange}
                                error={!!formErrors.DurationeType}
                                helperText={formErrors.DurationeType}
                                sx={{ marginBottom: 3 }}
                            >
                                <MenuItem value="Day">Day</MenuItem>
                                <MenuItem value="Hours">Hours</MenuItem>
                            </TextField>
                        </Grid>


                        {/* Project Name Field */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ marginBottom: 3 }} error={!!formErrors.Project}>
                                <InputLabel id="project-name-label">Project Name</InputLabel>
                                <Select
                                    labelId="project-name-label"
                                    id="Project"
                                    name="Project"
                                    value={formValues.Project}
                                    onChange={handleChange}
                                    label="Project Name"
                                >
                                    {PROJECTS.map((project) => (
                                        <MenuItem key={project.project_id} value={project.project_id}>
                                            {project.project_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{formErrors.Project}</FormHelperText>
                            </FormControl>
                        </Grid>

          

                        {/* Select Task Field */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ marginBottom: 3 }} error={!!formErrors.SubTask}>
                                <InputLabel id="select-task-label">Select Task</InputLabel>
                                <Select
                                    labelId="select-task-label"
                                    id="SubTask"
                                    name="SubTask"
                                    multiple
                                    value={formValues.SubTask}
                                    onChange={handleMultiSelectChange}
                                    renderValue={(selected) =>
                                        selected.map(id => {
                                            const task = TASKS.find(task => task.SubTaskId === id);
                                            return task ? task.SubTaskName : '';
                                        }).join(', ')
                                    }
                                >
                                    {TASKS.map((task) => (
                                        <MenuItem key={task.SubTaskId} value={task.SubTaskId}>
                                            {task.SubTaskName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{formErrors.SubTask}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="Description"
                                name="Description"
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={formValues.Description}
                                onChange={handleChange}
                                error={!!formErrors.Description}
                                helperText={formErrors.Description}
                                sx={{ marginBottom: 3 }}
                            />
                        </Grid> 

                        {/* Select Team Members (Multiple) */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ marginBottom: 3 }} error={!!formErrors.TeamId}>
                                <InputLabel id="team-id-label">Member Name</InputLabel>
                                <Select
                                    labelId="team-id-label"
                                    id="TeamId"
                                    name="TeamId"
                                    multiple
                                    value={formValues.TeamId}
                                    onChange={handleMultiSelectChange}
                                    renderValue={(selected) =>
                                        selected.map(id => {
                                            const member = Member.find(member => member.Name_id === id);
                                            return member ? member.Name_name : '';
                                        }).join(', ')
                                    }
                                >
                                    {Member.map((member) => (
                                        <MenuItem key={member.Name_id} value={member.Name_id}>
                                            {member.Name_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{formErrors.TeamId}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {/* Task Type Selection */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ marginBottom: 3 }} error={!!formErrors.Type}>
                                <InputLabel id="task-type-label">Task Type</InputLabel>
                                <Select
                                    labelId="task-type-label"
                                    id="Type"
                                    name="Type"
                                    value={formValues.Type}
                                    onChange={handleChange}
                                    label="Task Type"
                                >
                                    <MenuItem value="One Time">One Time</MenuItem>
                                    <MenuItem value="Daily">Daily</MenuItem>
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    <MenuItem value="Semiannualy">Semiannualy</MenuItem>
                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                </Select>
                                <FormHelperText>{formErrors.Type}</FormHelperText>
                            </FormControl>
                        </Grid>
                        {formValues.Type === "Weekly" ? (
                            <Grid item xs={12} sm={6}>

                                <FormControl fullWidth sx={{ marginBottom: 3 }} error={!!formErrors.Type}>
                                    <InputLabel id="task-type-label">Select Week</InputLabel>
                                    <Select
                                        labelId="task-type-label"
                                        id="Week"
                                        name="Week"
                                        value={formValues.Week}
                                        onChange={handleChange}
                                        label="Task Type"
                                    >
                                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                                            <MenuItem key={day} value={day}>{day}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{formErrors.Type}</FormHelperText>
                                </FormControl>
                            </Grid>

                        ) : (
                            (formValues.Type === "One Time" || formValues.Type === "Daily") ? null : (
                                formValues.Type && (
                                    <>
                                        <Grid item xs={12} sm={6}>

                                            <FormControl fullWidth sx={{ marginBottom: 3 }} error={!!formErrors.Date}>
                                                <InputLabel id="date-label">Select Date</InputLabel>
                                                <Select
                                                    labelId="date-label"
                                                    id="Date"
                                                    name="Date"
                                                    value={formValues.Date}
                                                    onChange={handleChange}
                                                    label="Date"
                                                >
                                                    {/* Month Selector */}
                                                    {[...Array(12).keys()].map(month => (
                                                        <MenuItem key={month} value= {new Date(0, month).toLocaleString('default', { month: 'long' })}>
                                                            {new Date(0, month).toLocaleString('default', { month: 'long' })}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>{formErrors.Date}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                id="day"
                                                name="Day"
                                                label="Select Day"
                                                type="number"
                                                value={formValues.Day}
                                                onChange={handleChange}
                                                error={!!formErrors.Day}
                                                helperText={formErrors.Day}
                                                InputProps={{ inputProps: { min: 1, max: 31 } }}
                                            />
                                        </Grid>
                                    </>
                                )
                            )
                        )}
                    </Grid>

                    {/* Submit Button */}
                    <Button variant="contained" color="primary" type="submit" sx={{ marginTop: 2 }}>
                        Submit
                    </Button>
                </Box>
            </Row>
            {/* Table to display tasks */}
            <Row className='spacer'>

                <Paper
                    sx={{
                        height: 400,
                        width: '100%',
                        overflow: 'hidden',
                        borderRadius: '20px 20px 0 0',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    }}
                >
                    <DataGrid
                        rows={rows} // Safely access rows
                        columns={columns} // Safely access columns
                        pagination
                        pageSize={5}
                        rowsPerPageOptions={[5, 10]}
                        sx={{
                            border: 0,
                            borderRadius: '0px',
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: 'black', // Background color for the entire header row
                                color: 'white', // Text color in the header
                                borderRadius: '20px 20px 0 0',
                                boxShadow: 'none',
                            },
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#91C7EE', // Ensure column header background color matches
                                color: '#074C7D',
                                borderBottom: ` 1px solid ${theme.palette.divider}`,
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: `1px solid ${theme.palette.divider}`,
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                        }}
                        columnVisibilityModel={{
                            ...(isMobile ? { position: false } : {}),
                        }}
                    />
                </Paper>
            </Row>
        </div >
    );
};

export default Task_Form;