import { createContext, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Sub_taskContext = createContext();

export const SubTaskContextProvider = ({ children }) => {

    const BaseUrl = "https://customerandtask-app.onrender.com"

    const [SubTaskData, setSubTaskData] = useState();
    const Addmember = (Data ,member) => {
        axios
            .post(`${BaseUrl}/SubTaskMaster`, {
                SubTaskName: Data,
                TeamId: member
            })
            .then(function (response) {
                setSubTaskData(response)
                // console.log(response);

                if (response?.data.status === "SubTask Success create") {
                    toast.success('Task added successfully!', {
                        position: "top-right",
                        autoClose: 3000,
                    });
                } else {
                    toast.error('Failed to add memberssss. Please try again.');
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const SubTaskDataFun = () => {
        axios
            .get(`${BaseUrl}/SubTaskMaster/SubTaskFind`)
            .then(function (response) {
                setSubTaskData(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const SubTastDelete = (id) => {
        axios
            .get(`${BaseUrl}/SubTaskMaster/SubTaskdelete/${id}`)
            .then(function (response) {
                setSubTaskData(response)
                if (response?.data.status === "SubTask Success Delete") {
                    toast.success('Task added Delete!', {
                        position: "top-right",
                        autoClose: 3000,
                    });
                } else {
                    toast.error('SubTask Assigning in MainTask, please remove it from task master.');
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const UpdateSubTask = (id,subTaskName,memberIds) => {

        axios
            .post(`${BaseUrl}/SubTaskMaster/SubTaskUpdate/${id}`, {
                SubTaskName: subTaskName,
                TeamId: memberIds
            })
            .then(function (response) {
                setSubTaskData(response)
                if (response?.data.status === "SubTask Success Update") {
                    toast.success(' SubTask Success Update', {
                        position: "top-right",
                        autoClose: 3000,
                    });
                } else {
                    toast.error('Failed to Upadate SubTask. Please try again.');
                }

            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    // Project 

    const [ProjectData, setProjectData] = useState();

    const ProjectDataFun = () => {

        axios
            .get(`${BaseUrl}/TablemangeController/show_project`)
            .then(function (response) {
                setProjectData(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const [SubTaskFun,setSubTaskFun] = useState()
    const SubTaskid = (id) => {

        axios
            .get(`${BaseUrl}/SubTaskMaster/PSubTaskfindId/${id}`)
            .then(function (response) {
                setSubTaskFun(response)
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    return (
        <Sub_taskContext.Provider
            value={{
                SubTaskData, Addmember, SubTaskDataFun, SubTastDelete, UpdateSubTask,
                ProjectData, ProjectDataFun,SubTaskid,SubTaskFun
            }}
        >
            {children}
        </Sub_taskContext.Provider>
    );
};

// Add prop-types validation
SubTaskContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
