import { createContext, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ShowAllTaskContext = createContext();

export const ShowAllTaskContextProvider = ({ children }) => {

    const BaseUrl = "https://customerandtask-app.onrender.com"

    const [ComplainData, setComplainData] = useState();
   
    const CompainFun = () => {
        axios
            .get(`${BaseUrl}/ConplainController/dfshfsdfgjftghjfghjghx`)
            .then(function (response) {
                setComplainData(response)
            })  
            .catch(function (error) {
                // console.log(error);
            });
    }
    const [ComalainIFData, setComalainIFData] = useState();

    const ComalainIF = (id) => {
        axios
            .get(`${BaseUrl}/ConplainController/dfshfsdfgjftghjfghjgh/${id}`)
            .then(function (response) {
                setComalainIFData(response)
            })  
            .catch(function (error) {
                // console.log(error);
            });
    }

    const [TaskData, setTaskData] = useState();
   
    const TaskShowFun = () => {
        axios
            .get(`${BaseUrl}/TaskManagement/AssignTaskDataFun`)
            .then(function (response) {
                setTaskData(response)
            })  
            .catch(function (error) {
                // console.log(error);
            });
    }

    const [SubTaskData, setSubTaskData] = useState();

    const SubTaskShowFun = () => {
        axios
            .get(`${BaseUrl}/TaskManagement/AssignSubTaskDataFun`)
            .then(function (response) {
                setSubTaskData(response)
            })  
            .catch(function (error) {
                // console.log(error);
            });
    }

    

  
    return (
        <ShowAllTaskContext.Provider
            value={{
                CompainFun,ComplainData,ComalainIF,ComalainIFData,TaskShowFun,TaskData,
                SubTaskData,SubTaskShowFun
            }}
        >
            {children}
        </ShowAllTaskContext.Provider>
    );
};

// Add prop-types validation
ShowAllTaskContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
