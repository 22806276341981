import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Box, Paper, Card, CardContent } from '@mui/material';
import { ShowAllTaskContext } from '../Context/ShowAllTask';
import './Customerdetails.css'
export const CustomerDetails = () => {

    const { ComalainIF, ComalainIFData } = useContext(ShowAllTaskContext);
    const { id } = useParams();

    useEffect(() => {
        ComalainIF(id);
    }, [id]);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    };

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom color="primary">
                    Customer Requary Details
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card elevation={1} sx={{ borderRadius: 2 }}>
                            <CardContent>
                                <Typography variant="subtitle1">Date : {formatDate(ComalainIFData?.data?.data?.Date)}</Typography>
                                <Typography variant="subtitle1">Call NO : {ComalainIFData?.data?.data?.CallNo}</Typography>
                                <Typography variant="subtitle1">Company Name : {ComalainIFData?.data?.data?.CompanyName?.CompanyName}</Typography>
                                <Typography variant="subtitle1">Project : {ComalainIFData?.data?.data?.Project?.project_name}</Typography>
                                <Typography variant="subtitle1">Module : {ComalainIFData?.data?.data?.Module?.module_name}</Typography>
                                <Typography variant="subtitle1">Type : {ComalainIFData?.data?.data?.Type?.Type_name}</Typography>
                                <Typography variant="subtitle1">Present status : {ComalainIFData?.data?.data?.PresentStatus}</Typography>
                                <Typography variant="subtitle1">Requirement : {ComalainIFData?.data?.data?.Requirement}</Typography>
                                <Typography variant="subtitle1">Solution : {ComalainIFData?.data?.data?.Solution}</Typography>
                                <Typography variant="subtitle1">Benefit : {ComalainIFData?.data?.data?.Benefit}</Typography>
                                <Typography variant="subtitle1">Employ Name : {ComalainIFData?.data?.data?.SuportTeamId?.Name}</Typography>
                                <Typography variant="subtitle1">Status : {ComalainIFData?.data?.data?.Status}</Typography>
                                <Typography variant="subtitle1">Post By : {ComalainIFData?.data?.data?.Partition}</Typography>
                                <Typography variant="subtitle1">Contact Person name : {ComalainIFData?.data?.data?.ContactPer}</Typography>
                                <Typography variant="subtitle1">Contact Person number : {ComalainIFData?.data?.data?.ContactPerName}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>

            <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mt: 3 }}>
                <Typography variant="h4" gutterBottom color="primary">
                    Photos
                </Typography>
                <Grid container spacing={3}>
                    {ComalainIFData?.data?.data?.Photos?.map((el, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card elevation={1} sx={{ borderRadius: 2 }}>
                                <CardContent>
                                    <Box
                                        sx={{
                                            height: 200,
                                            border: '1px dashed grey',
                                            borderRadius: 2,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            bgcolor: '#f5f5f5'
                                        }}
                                    >
                                        <img src={el} alt="" style={{ maxHeight: '100%', maxWidth: '100%' }} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Paper>

            <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mt: 3 }}>
                <Typography variant="h4" gutterBottom color="primary">
                    Record
                </Typography>
                <Grid container spacing={3}>
                    {ComalainIFData?.data?.data?.Record?.map((el, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card elevation={1} sx={{ borderRadius: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1">Date : {formatDate(el?.Date)}</Typography>
                                    <Typography variant="subtitle1">Name : {el?.InHouseId?.Name}</Typography>
                                    <Typography variant="subtitle1">Description : {el?.DescripTione}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Box>
    );
};
