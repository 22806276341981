import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { MemberContextProvider } from './Context/AddMember';
import { SubTaskContextProvider } from './Context/Sub_task';
import { AuthContextProvider } from './Context/Auth';
import { TastMasterProvider } from './Context/Task_master';
import { CustomerContextProvider } from './Context/AddCustomer';
import { ShowAllTaskContextProvider } from './Context/ShowAllTask';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <MemberContextProvider>
      <SubTaskContextProvider>
        <AuthContextProvider>
          <TastMasterProvider>
            <CustomerContextProvider>
              <ShowAllTaskContextProvider>
              <App />
            </ShowAllTaskContextProvider>
          </CustomerContextProvider>
        </TastMasterProvider>
      </AuthContextProvider>
    </SubTaskContextProvider>
  </MemberContextProvider>
  </BrowserRouter >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
