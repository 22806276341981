import React from 'react';
import Add_Member from '../Components/Add_Member';
import "./Add_member.css";
import Table from '../Mui/Table';

const Add_member = () => {
  return (
    <div>
      <div className="containers">
      <div className="add-text mt-3">
        <h3>Add Member</h3>
      </div>
        <div className=" spacer ">
          <Add_Member />
        </div>
        <div>
        </div>
      </div>
    </div>
  );
}

export default Add_member;
