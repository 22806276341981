import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import React, { useEffect } from 'react';
import Layout from './Layout/Layout';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'

const App = () => {
    
    return (
        <div className='overflow-hidden'>
            <Layout />
        </div>
    );
};

export default App;
