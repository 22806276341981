import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Table from '../Mui/Table';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomerContext } from '../Context/AddCustomer';

const Add_Customer = () => {
    const { AddCustomer, UpdateCustomer, CustomerDataFun, CustomerData } = useContext(CustomerContext);
    const [showPassword, setShowPassword] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null); // State to hold selected customer for editing

    useEffect(() => {
        CustomerDataFun();
    }, []);

    const formik = useFormik({
        initialValues: {
            CompanyName: '',
            Email: '',
            Password: '',
            MobileNumber: '',
            Address: '',
        },
        validationSchema: Yup.object({
            CompanyName: Yup.string()
                .min(3, 'At least 3 characters')
                .required('Required'),
            Email: Yup.string().email('Invalid email').required('Required'),
            MobileNumber: Yup.string()
                .matches(/^[0-9]{10}$/, '10-digit number required')
                .required('Required'),
            Password: Yup.string()
                .min(6, 'At least 6 characters')
                .required('Required'),
            Address: Yup.string()
                .min(10, 'At least 10 characters')
                .required('Required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            if (editMode) {
                // Update customer
                await UpdateCustomer(selectedCustomer.Mainid, values);
            } else {
                // Add new customer
                await AddCustomer(values);
            }
            resetForm();
            setEditMode(false); // Reset edit mode
            setSelectedCustomer(null); // Reset selected customer
        },
    });

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'CompanyName', headerName: 'Company Name', flex: 1 },
        { field: 'MobileNumber', headerName: 'Mobile Number', flex: 1 },
        {
            field: 'edit',
            headerName: 'Edit',
            flex: 1,
            renderCell: (params) => (
                <button onClick={() => handleEdit(params.row)} className='btn btn-primary'>Edit</button>
            )
        },
    ];

    const handleEdit = (customer) => {
        setSelectedCustomer(customer); // Set the customer to be edited
        setEditMode(true); // Enable edit mode
        formik.setValues({
            CompanyName: customer.CompanyName,
            Email: customer.Email,
            Password: customer.Password, 
            MobileNumber: customer.MobileNumber,
            Address: customer.Address,
        }); // Populate form with customer data
    };

    const rows = CustomerData?.data?.data?.map((el, index) => ({
        id: el.id || index + 1,
        Mainid: el._id,
        CompanyName: el.CompanyName,
        MobileNumber: el.ContactNo,
        Email: el.Email, // Ensure Email is part of the row data for editing
        Address: el.Address, // Ensure Address is part of the row data for editing
        Password: el.Password,

    })) || [];

    return (
        <div className="container">
            <form onSubmit={formik.handleSubmit} className='Add_Member box-add_member mt-5'>
                <div className="row g-3">
                    <div className="col-lg-12">
                        <input
                            id="CompanyName"
                            name="CompanyName"
                            placeholder='Company Name'
                            type="text"
                            className="form-control"
                            {...formik.getFieldProps('CompanyName')}
                        />
                        {formik.touched.CompanyName && formik.errors.CompanyName && (
                            <div style={{ color: 'red' }}>{formik.errors.CompanyName}</div>
                        )}
                    </div>

                    <div className="col-lg-6">
                        <input
                            id="MobileNumber"
                            name="MobileNumber"
                            placeholder='Contact Number'
                            type="text"
                            className="form-control"
                            {...formik.getFieldProps('MobileNumber')}
                        />
                        {formik.touched.MobileNumber && formik.errors.MobileNumber && (
                            <div style={{ color: 'red' }}>{formik.errors.MobileNumber}</div>
                        )}
                    </div>

                    <div className="col-lg-6">
                        <input
                            id="Email"
                            name="Email"
                            placeholder='Email Address'
                            type="email"
                            className="form-control"
                            {...formik.getFieldProps('Email')}
                        />
                        {formik.touched.Email && formik.errors.Email && (
                            <div style={{ color: 'red' }}>{formik.errors.Email}</div>
                        )}
                    </div>

                    <div className="col-lg-6">
                        <input
                            id="Password"
                            name="Password"
                            placeholder='Password'
                            type={showPassword ? 'text' : 'password'}
                            className="form-control"
                            {...formik.getFieldProps('Password')}
                        />
                        {formik.touched.Password && formik.errors.Password && (
                            <div style={{ color: 'red' }}>{formik.errors.Password}</div>
                        )}
                    </div>

                    <div className="col-lg-6">
                        <input
                            id="Address"
                            name="Address"
                            placeholder='Address'
                            type="text"
                            className="form-control"
                            {...formik.getFieldProps('Address')}
                        />
                        {formik.touched.Address && formik.errors.Address && (
                            <div style={{ color: 'red' }}>{formik.errors.Address}</div>
                        )}
                    </div>

                    <div className="col-12 d-flex justify-content-center mt-2">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ padding: '10px 20px', fontSize: '16px' }}
                        >
                            {editMode ? 'Update' : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
            <ToastContainer />
            <div className="Home-recent-member spacer">
                <h2 style={{ fontSize: '34px' }} className='text'>Recent Members</h2>
            </div>

            <div className="mt-5">
                <Table columns={columns} rows={rows} />
            </div>
        </div>
    );
};

export default Add_Customer;
