import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Table = ({ columns = [], rows = [] }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Ensure columns and rows are valid arrays
  if (!Array.isArray(columns) || !Array.isArray(rows)) {
    return <div>Error: Invalid table data</div>;
  }

  return (
    <Paper
      sx={{
        height: 400,
        width: '100%',
        overflow: 'hidden',
        borderRadius: '20px 20px 0 0',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
      }}
    >
      <DataGrid
        rows={rows} // Safely access rows
        columns={columns} // Safely access columns
        pagination
        pageSize={5}
        rowsPerPageOptions={[5, 10]}
        sx={{
          border: 0,
          borderRadius: '0px',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'black', // Background color for the entire header row
            color: 'white', // Text color in the header
            borderRadius: '20px 20px 0 0',
            boxShadow: 'none',
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#91C7EE', // Ensure column header background color matches
            color: '#074C7D',
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
          },
        }}
        columnVisibilityModel={{
          ...(isMobile ? { position: false } : {}),
        }}
      />
    </Paper>
  );
};

export default Table;
