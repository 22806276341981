import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Routes, Navigate } from 'react-router-dom';
import Add_member from '../Page/Add_member';
import Assign_task from '../Page/Assign_task';
import Dashboard from '../Page/Dashboard';
import Home from '../Page/Home';
import Show_All_Task from '../Page/Show_All_Task';
import Show_All_member from '../Page/Show_All_member';
import Sub_Task_Project_Master from '../Page/Sub_Task_Project_Master';
import Task_Master from '../Page/Task_Master';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../TopHeader/Header';
import Login from '../Login/Login';
import Cookies from 'js-cookie'; // Import js-cookie
import Add_Customer from '../Page/Add_Customer';
import Show_All_Customer from '../Page/Show_All_Customer';
import DetailPopup from '../Components/DetailsPopup';
import { CustomerDetails } from '../Page/CustomerDetails';
import MainTask from '../Page/MainTask';
import SubTask from '../Page/SubTask';
import CustomerReq from '../Page/CustomerReq';
import TaskDetailsDetail from '../Page/TaskDetailsDetail';
import { TaskProfile } from '../Page/TaskProfile';
import EditSubTask from '../Page/EditSubTask';
import AssigntaskDetails from '../Page/assigntaskDetails';

const Layout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const loginData = Cookies.get('loginData');
    const parsedData = loginData ? JSON.parse(loginData) : null;  // Use null if loginData is undefined

    useEffect(() => {
        if (parsedData && parsedData.data) {
            setIsAuthenticated(true);
        }
    }, []);


    const toggleSidebar = () => {
        setIsSidebarOpen(prev => !prev);

        const width = window.innerWidth;
        const header = document.querySelector('.header');
        header.style.paddingLeft = isSidebarOpen ? "0%" : "25%";
    };


    const sidebarStyle = (isSidebarOpen) => ({
        width: isSidebarOpen ? '250px' : '0',
        transition: 'width 0.9s ease',
        overflow: 'hidden',
    });

    // Content column style with transition
    const contentStyle = (isSidebarOpen) => ({
        marginLeft: isSidebarOpen ? '250px' : '0',
        transition: 'margin-left 0.3s ease',
    });

    // Simulating login action
    const handleLogin = () => {
        setIsAuthenticated(true);
    };


    // jenilvaghasiy@gmail.com
    if (!isAuthenticated) {
        // Redigfhipfgnhfgip    rect to login if not authenticated
        return <Login onLogin={handleLogin} />;
    }
    // console.log(isAuthenticated);

    return (
        <Row>
            <Header onToggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
            {/* Sidebar column */}
            <Col lg={isSidebarOpen ? 3 : 0} xl={isSidebarOpen ? 2 : 0} className={`sidebar-column ${isSidebarOpen ? 'open' : 'closed'}`}>
                {isSidebarOpen && <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}
            </Col>
            <Col lg={isSidebarOpen ? 9 : 12} xl={isSidebarOpen ? 10 : 12} className="content-column">
                <Routes>
                    {
                        parsedData?.data?.Role === "owner" ? (
                            <>
                                <Route path='/' element={<Home />} />
                                <Route path='/addmember' element={<Add_member />} />
                                <Route path='/addcustomer' element={<Add_Customer />} />
                                <Route path='/showalltask' element={<Show_All_Task />} />
                                <Route path='/showallmember' element={<Show_All_member />} />
                                <Route path='/assigntask' element={<Assign_task />} />
                                <Route path='/assigntask/TaskDetailsDetail/:id' element={<AssigntaskDetails />} />
                                <Route path='/taskmaster' element={<Task_Master />} />
                                <Route path='/subtask' element={<Sub_Task_Project_Master />} />
                                <Route path='/subtask/editsubtask/:id' element={<EditSubTask />} />
                                <Route path='/showcustomer' element={<Show_All_Customer />} />
                                <Route path='/DetailPopup' element={<DetailPopup />} />
                                <Route path='/CustomerrequustDetail/:id' element={<CustomerDetails />} />
                                <Route path='/MainTask/TaskDetailsDetail/:id' element={<TaskDetailsDetail />} />
                                <Route path='/showalltask/TaskDetailsDetail/:id' element={<TaskDetailsDetail />} />
                                <Route path='/task-profile/:id' element={<TaskProfile />} />
                                <Route path='*' element={<Navigate to="/" />} />
                            </>
                        ) : (
                            <>
                                {
                                    parsedData?.data?.Role === "inhouse" ? (
                                        <>
                                            {/* <Route path='/dashboard ' element={<Dashboard />} /> */}
                                            <Route path='/' element={<MainTask />} />
                                            <Route path='/MainTask/TaskDetailsDetail/:id' element={<TaskDetailsDetail />} />
                                            <Route path='/SubTask' element={<SubTask />} />
                                            <Route path='/CustomerReq' element={<CustomerReq />} />
                                            <Route path='CustomerReq/CustomerrequustDetail/:id' element={<CustomerDetails />} />

                                            <Route path='*' element={<Navigate to="/" />} />
                                        </>
                                    ) : (
                                        <>
                                            <Route path='/' element={<MainTask />} />
                                            <Route path='/subtask/editsubtask/:id' element={<EditSubTask />} />
                                            <Route path='/MainTask/TaskDetailsDetail/:id' element={<TaskDetailsDetail />} />
                                            <Route path='/assigntask/TaskDetailsDetail/:id' element={<AssigntaskDetails />} />
                                            <Route path='/SubTask' element={<SubTask />} />
                                            <Route path='/CustomerReq' element={<CustomerReq />} />
                                            <Route path='CustomerReq/CustomerrequustDetail/:id' element={<CustomerDetails />} />
                                            <Route path='*' element={<Navigate to="/" />} />
                                            <Route path='/showallmember' element={<Show_All_member />} />
                                            <Route path='/addmember' element={<Add_member />} />
                                            <Route path='/assigntask' element={<Assign_task />} />
                                            <Route path='/taskmaster' element={<Task_Master />} />
                                            <Route path='/subtaskmaster' element={<Sub_Task_Project_Master />} />
                                            <Route path='/task-profile/:id' element={<TaskProfile />} />
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </Routes>
            </Col>
        </Row>
    );
};

export default Layout;
