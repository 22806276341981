import {
    TextField, MenuItem, Button, FormControl, InputLabel, Select, FormHelperText, Box,
    Typography, Grid, Paper, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, useTheme,
    Card,
    CardContent
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { TastMasterContext } from '../Context/Task_master';
import { Col, Row } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { MemberContext } from '../Context/AddMember';

const AssigntaskDetails = () => {

    const theme = useTheme();

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedAssingSubTask, setSelectedAssingSubTask] = useState(null);
    const [selectedMemberId, setSelectedMemberId] = useState('');


    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    };
    const { id } = useParams();

    const { ShowAssingSubTask,AssingSubTask , AssingsubTaskChangeMember } = useContext(TastMasterContext);
    const { MemberDataActiveFun, activeuser } = useContext(MemberContext);


    useEffect(() => {
        ShowAssingSubTask(id);
        MemberDataActiveFun();
    }, [])

    const handleAcceptTask = (id) => {
        setSelectedAssingSubTask(id);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false); // Close the dialog
    };

    const handleDialogSubmit = () => {
        AssingsubTaskChangeMember(selectedAssingSubTask, selectedMemberId,id);

        
        setOpenDialog(false);
    };

    const handleMemberChange = (event) => {
        setSelectedMemberId(event.target.value);
    };



    const Columns = [
        { field: 'id', headerName: 'Id', flex: 1 },
        { field: 'Member', headerName: 'Member', flex: 1 },
        { field: 'Status', headerName: 'Status', flex: 1 },
        { field: 'SubTaskName', headerName: 'Sub Task Name', flex: 1 },
        { field: 'AssignDate', headerName: 'Assign Date', flex: 1 },
        {
            field: 'acceptTask', headerName: 'Accept Task', flex: 1,
            renderCell: (params) => (
                params.row.Status !== "Panding" ? (
                    <></>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleAcceptTask(params.row.MainId)}
                    >
                        Assign Change
                    </Button>
                )
            ),
        },
    ];

    const Rows = AssingSubTask?.data?.data1?.map((el, index) => ({
        id: index + 1,
        MainId: el._id,
        Member: el?.MemberId?.Name,
        Status: el.Status,
        AssignDate: formatDate(el.AssignDate),
        SubTaskName:el.SubTaskId.SubTaskName
    })) || [];
    return (
        <>
            <Box sx={{ p: 3 }}>
                <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h4" gutterBottom color="primary">
                        Task Detalis
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card elevation={1} sx={{ borderRadius: 2 }}>
                                <CardContent>
                                    <Typography variant="subtitle1">Remark : {AssingSubTask?.data?.data?.Remark}</Typography>
                                    <Typography variant="subtitle1">Assign Date : {formatDate(AssingSubTask?.data?.data?.AssignDate)}</Typography>
                                    <Typography variant="subtitle1">Assigned By : {AssingSubTask?.data?.data?.AssignedBy?.Name}</Typography>
                                    <Typography variant="subtitle1">Company Name : {AssingSubTask?.data?.data?.CompanyId?.CompanyName}</Typography>
                                    <Typography variant="subtitle1">Due Date : {formatDate(AssingSubTask?.data?.data?.DueDate)}</Typography>
                                    <Typography variant="subtitle1">Description : {AssingSubTask?.data?.task?.Description}</Typography>
                                    <Typography variant="subtitle1">Project : {AssingSubTask?.data?.task?.Project?.project_name}</Typography>
                                    <Typography variant="subtitle1">TastName : {AssingSubTask?.data?.task?.TastName}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <div style={{ marginLeft: "50px" }} className="add-text mt-3">
                <h3>Assign Sub Task List</h3>
            </div>

            <Row className='spacer'>
                <Col lg={12}>
                    <Paper
                        sx={{
                            height: 400,
                            width: '100%',
                            overflow: 'hidden',
                            borderRadius: '20px 20px 0 0',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        }}
                    >
                        <DataGrid
                            rows={Rows}
                            columns={Columns}
                            pagination
                            pageSize={5}
                            rowsPerPageOptions={[5, 10]}
                            sx={{
                                border: 0,
                                borderRadius: '0px',
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: '20px 20px 0 0',
                                    boxShadow: 'none',
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: '#91C7EE',
                                    color: '#074C7D',
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 'bold',
                                },
                                '& .MuiDataGrid-cell': {
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    visibility: 'hidden',
                                },
                            }}
                        />
                    </Paper>
                </Col>
            </Row>

            {/* Dialog for changing task assignment */}
            <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>Change Task Assignment</DialogTitle>
                <DialogContent>
                    <Grid >
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{ marginBottom: 3 }}
                            >
                                <InputLabel id="memberId-label">Member ID</InputLabel>
                                <Select
                                    labelId="memberId-label"
                                    id="MemberId"
                                    name="MemberId"
                                    value={selectedMemberId} // Use separate state
                                    onChange={handleMemberChange} // Handle selection change
                                    label="Member ID"
                                >
                                    {activeuser?.data?.data?.map((member) => (
                                        <MenuItem key={member._id} value={member._id}>{member.Name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogSubmit} color="secondary">
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AssigntaskDetails
